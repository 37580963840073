export let softs = [
 {
  "id": "1",
  "ini": "い",
  "name": "EZ-TALK 初級編1",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "い",
  "name": "EZ-TALK 初級編2",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "い",
  "name": "EZ-TALK 初級編3",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "い",
  "name": "EZ-TALK 初級編4",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "い",
  "name": "EZ-TALK 初級編5",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "い",
  "name": "EZ-TALK 初級編6",
  "maker": "キューネット",
  "releaseDate": "2001/3/21",
  "price": "6000",
  "genre": "学習・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "え",
  "name": "F-ZERO FOR GAMEBOY ADVANCE",
  "maker": "任天堂",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "し",
  "name": "JGTO公認 GOLF MASTER JAPAN GOLF TOUR GAME",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "し",
  "name": "Jリーグポケット",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "あ",
  "name": "アドバンスGTA",
  "maker": "エム・ティー･オー",
  "releaseDate": "2001/3/21",
  "price": "4980",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "う",
  "name": "ウイニングポスト for ゲームボーイアドバンス",
  "maker": "コーエー",
  "releaseDate": "2001/3/21",
  "price": "5600",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "く",
  "name": "くるくるくるりん",
  "maker": "任天堂",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "こ",
  "name": "コナミ ワイワイレーシング アドバンス",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "す",
  "name": "スーパーマリオアドバンス",
  "maker": "任天堂",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "ち",
  "name": "チューチューロケット！",
  "maker": "セガ",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "と",
  "name": "トゥイティーのハーティーパーティー",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2001/3/21",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "な",
  "name": "ナポレオン",
  "maker": "任天堂",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "は",
  "name": "バトルネットワーク ロックマンエグゼ",
  "maker": "カプコン",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "は",
  "name": "パワプロクンポケット3",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "ひ",
  "name": "ピノビィーの大冒険",
  "maker": "ハドソン",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "ふ",
  "name": "ファイヤープロレスリングＡ",
  "maker": "スパイク",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "ふ",
  "name": "プレイノベル サイレントヒル",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "サウンドノベル",
  "icNo": "1"
 },
 {
  "id": "23",
  "ini": "ほ",
  "name": "ぼくは航空管制官",
  "maker": "タム",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "み",
  "name": "ミスタードリラー2",
  "maker": "ナムコ",
  "releaseDate": "2001/3/21",
  "price": "4800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "も",
  "name": "モンスターガーディアンズ",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "あ",
  "name": "悪魔城ドラキュラ Circle of the Moon",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "せ",
  "name": "全国GT選手権",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2001/3/21",
  "price": "5200",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "も",
  "name": "桃太郎まつり",
  "maker": "ハドソン",
  "releaseDate": "2001/3/21",
  "price": "5200",
  "genre": "ミニゲーム・RPG",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "は",
  "name": "爆熱ドッジボールファイターズ",
  "maker": "アトラス",
  "releaseDate": "2001/3/21",
  "price": "4980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "ゆ",
  "name": "遊☆戯☆王ダンジョンダイスモンスターズ",
  "maker": "コナミ",
  "releaseDate": "2001/3/21",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "け",
  "name": "GetBackers 奪還屋 地獄のスカラムーシュ",
  "maker": "コナミ",
  "releaseDate": "2001/4/26",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "た",
  "name": "耽美夢想マイネリーベ",
  "maker": "コナミ",
  "releaseDate": "2001/4/26",
  "price": "5800",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "す",
  "name": "スペースヘキサイト メーテルレジェンドEX",
  "maker": "ジョルダン",
  "releaseDate": "2001/4/27",
  "price": "5300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "と",
  "name": "ドラえもん 緑の惑星ドキドキ大救出！",
  "maker": "エポック社",
  "releaseDate": "2001/4/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "ほ",
  "name": "ボンバーマンストーリー",
  "maker": "ハドソン",
  "releaseDate": "2001/4/27",
  "price": "5200",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "ふ",
  "name": "ファイナルファイトＯＮＥ",
  "maker": "カプコン",
  "releaseDate": "2001/5/25",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "た",
  "name": "タクティクスオウガ外伝 The Knight of Lodis",
  "maker": "任天堂",
  "releaseDate": "2001/6/21",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "と",
  "name": "トイロボフォース",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2001/6/28",
  "price": "5200",
  "genre": "シューティング・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "ち",
  "name": "チョロQアドバンス",
  "maker": "タカラ",
  "releaseDate": "2001/6/29",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "な",
  "name": "なりきりジョッキーゲーム 優駿ラプソディ～♪",
  "maker": "カプコン",
  "releaseDate": "2001/6/29",
  "price": "4800",
  "genre": "競馬・アクション・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ5 エキスパート1",
  "maker": "コナミ",
  "releaseDate": "2001/7/5",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "ふ",
  "name": "ブレス オブ ファイア 竜の戦士",
  "maker": "カプコン",
  "releaseDate": "2001/7/6",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "も",
  "name": "森田将棋あどばんす",
  "maker": "ハドソン",
  "releaseDate": "2001/7/12",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "ま",
  "name": "麻雀刑事",
  "maker": "ハドソン",
  "releaseDate": "2001/7/12",
  "price": "5200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "い",
  "name": "EXモノポリー",
  "maker": "タカラ",
  "releaseDate": "2001/7/13",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "す",
  "name": "スーパーストリートファイターⅡX リバイバル",
  "maker": "カプコン",
  "releaseDate": "2001/7/13",
  "price": "4800",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "み",
  "name": "みんなの飼育シリーズ① ぼくのカブト虫",
  "maker": "エム・ティー･オー",
  "releaseDate": "2001/7/13",
  "price": "5200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "か",
  "name": "風のクロノア 夢見る帝国",
  "maker": "ナムコ",
  "releaseDate": "2001/7/19",
  "price": "5300",
  "genre": "パズル・アクション",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "ま",
  "name": "マリオカートアドバンス",
  "maker": "任天堂",
  "releaseDate": "2001/7/21",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "し",
  "name": "JGTO公認 GOLFMASTERモバイル JAPAN GOLF TOUR GAME",
  "maker": "コナミ",
  "releaseDate": "2001/7/26",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "す",
  "name": "スタコミ STAR COMMUNICATOR",
  "maker": "コナミ",
  "releaseDate": "2001/7/26",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "も",
  "name": "モバイルプロ野球 監督の采配",
  "maker": "コナミ",
  "releaseDate": "2001/7/26",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "き",
  "name": "筋肉番付 金剛君の大冒険！",
  "maker": "コナミ",
  "releaseDate": "2001/7/26",
  "price": "5800",
  "genre": "スポーツ・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "せ",
  "name": "ゼロ・ツアーズ",
  "maker": "メディアリング",
  "releaseDate": "2001/7/27",
  "price": "5480",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "55",
  "ini": "お",
  "name": "黄金の太陽 開かれし封印",
  "maker": "任天堂",
  "releaseDate": "2001/8/1",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "お",
  "name": "ドカポンQ モンスターハンター！",
  "maker": "アスミック・エースエンタテインメント",
  "releaseDate": "2001/8/3",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "み",
  "name": "みんなの飼育シリーズ② ぼくのクワガタ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2001/8/3",
  "price": "5200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "58",
  "ini": "し",
  "name": "ジュラシック・パークⅢ 恐竜にあいにいこう！",
  "maker": "コナミ",
  "releaseDate": "2001/8/9",
  "price": "5800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "す",
  "name": "スーパーブラックバス アドバンス",
  "maker": "スターフィッシュ",
  "releaseDate": "2001/8/10",
  "price": "4800",
  "genre": "釣り・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "き",
  "name": "極 麻雀デラックス 未来戦士21",
  "maker": "アテナ",
  "releaseDate": "2001/8/10",
  "price": "5200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "わ",
  "name": "ワリオランドアドバンス　ヨーキーのお宝",
  "maker": "任天堂",
  "releaseDate": "2001/8/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "62",
  "ini": "し",
  "name": "ジュラシック・パークⅢ アドバンスドアクション",
  "maker": "コナミ",
  "releaseDate": "2001/8/30",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "こ",
  "name": "高校受験アドバンスシリーズ 英語構文編 26units収録",
  "maker": "キーネット",
  "releaseDate": "2001/8/31",
  "price": "6800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "64",
  "ini": "こ",
  "name": "高校受験アドバンスシリーズ 英熟語編 650phrases収録",
  "maker": "キーネット",
  "releaseDate": "2001/8/31",
  "price": "6800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "こ",
  "name": "高校受験アドバンスシリーズ 英単語編 2000words収録",
  "maker": "キーネット",
  "releaseDate": "2001/8/31",
  "price": "6800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "め",
  "name": "メダロット・ナビ カブト",
  "maker": "イマジニア",
  "releaseDate": "2001/9/7",
  "price": "5800",
  "genre": "RPG・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "67",
  "ini": "め",
  "name": "メダロット・ナビ クワガタ",
  "maker": "イマジニア",
  "releaseDate": "2001/9/7",
  "price": "5800",
  "genre": "RPG・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "68",
  "ini": "ら",
  "name": "ラブひなアドバンス 祝福の鐘はなるかな",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2001/9/7",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "ろ",
  "name": "ロボットポンコッツ2 クロスバージョン",
  "maker": "ハドソン",
  "releaseDate": "2001/9/13",
  "price": "5200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "ろ",
  "name": "ロボットポンコッツ2 リングバージョン",
  "maker": "ハドソン",
  "releaseDate": "2001/9/13",
  "price": "5200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "71",
  "ini": "け",
  "name": "幻想水滸伝 カードストーリーズ",
  "maker": "コナミ",
  "releaseDate": "2001/9/13",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "ふ",
  "name": "FIELD OF NINE DIGITALE DITION 2001",
  "maker": "コナミ",
  "releaseDate": "2001/9/20",
  "price": "5800",
  "genre": "カード・スポーツ",
  "icNo": "1"
 },
 {
  "id": "73",
  "ini": "す",
  "name": "スーパーロボット大戦Ａ",
  "maker": "バンプレスト",
  "releaseDate": "2001/9/21",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "そ",
  "name": "Z.O.E 2173 TESTAMENT",
  "maker": "コナミ",
  "releaseDate": "2001/9/27",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "の",
  "name": "信長の野望",
  "maker": "コーエー",
  "releaseDate": "2001/9/28",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "に",
  "name": "西原理恵子の殿堂麻雀",
  "maker": "メディアリング",
  "releaseDate": "2001/9/28",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "は",
  "name": "ハテナサテナ",
  "maker": "ハドソン",
  "releaseDate": "2001/10/4",
  "price": "5200",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "き",
  "name": "奇々怪界あどばんす",
  "maker": "アルトロン",
  "releaseDate": "2001/10/5",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "79",
  "ini": "き",
  "name": "逆転裁判",
  "maker": "カプコン",
  "releaseDate": "2001/10/12",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "み",
  "name": "みんなでぷよぷよ",
  "maker": "セガ",
  "releaseDate": "2001/10/18",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "81",
  "ini": "は",
  "name": "ハムスター物語2 GBA",
  "maker": "カルチャーブレーン",
  "releaseDate": "2001/10/19",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "は",
  "name": "ハローキティコレクション ミラクルファッションメーカー",
  "maker": "イマジニア",
  "releaseDate": "2001/10/19",
  "price": "5200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "い",
  "name": "ESPN X Games skateboarding",
  "maker": "コナミ",
  "releaseDate": "2001/10/25",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "な",
  "name": "なかよし麻雀 かぶリーチ",
  "maker": "コナミ",
  "releaseDate": "2001/10/25",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "ひ",
  "name": "ヒカルの碁",
  "maker": "コナミ",
  "releaseDate": "2001/10/25",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "ど",
  "name": "どこでも対局 役満アドバンス",
  "maker": "任天堂",
  "releaseDate": "2001/10/26",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "な",
  "name": "なかよしペットアドバンスシリーズ① かわいいハムスター",
  "maker": "エム・ティー･オー",
  "releaseDate": "2001/10/26",
  "price": "5200",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "ふ",
  "name": "ファランクス",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2001/10/26",
  "price": "4980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "し",
  "name": "ジュラシック・パークⅢ 失われた遺伝子",
  "maker": "コナミ",
  "releaseDate": "2001/11/1",
  "price": "5800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "ゆ",
  "name": "友情のビクトリーゴール 4V4嵐 GET THE GOAL!!",
  "maker": "コナミ",
  "releaseDate": "2001/11/15",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "91",
  "ini": "あ",
  "name": "Adventure of TOKYO Disney SEA",
  "maker": "コナミ",
  "releaseDate": "2001/11/22",
  "price": "5800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "く",
  "name": "ぐるロジチャンプ",
  "maker": "コンパイル",
  "releaseDate": "2001/11/29",
  "price": "4980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "93",
  "ini": "そ",
  "name": "ZOIDS SAGA",
  "maker": "トミー",
  "releaseDate": "2001/11/30",
  "price": "5400",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "94",
  "ini": "お",
  "name": "億万長者ゲーム のっとり大作戦！",
  "maker": "タカラ",
  "releaseDate": "2001/11/30",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "き",
  "name": "機械化軍隊",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2001/11/30",
  "price": "5800",
  "genre": "RTS",
  "icNo": "1"
 },
 {
  "id": "96",
  "ini": "け",
  "name": "激闘！カーバトラーGO!!",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/11/30",
  "price": "4800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "さ",
  "name": "三國志",
  "maker": "コーエー",
  "releaseDate": "2001/11/30",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "98",
  "ini": "き",
  "name": "筋肉番付 決めろ！奇跡の完全制覇",
  "maker": "コナミ",
  "releaseDate": "2001/12/6",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "99",
  "ini": "は",
  "name": "爆転シュート ベイブレード 激闘！最強ブレーダー",
  "maker": "ブロッコリー",
  "releaseDate": "2001/12/6",
  "price": "5300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "あ",
  "name": "アドバンスラリー",
  "maker": "エム・ティー･オー",
  "releaseDate": "2001/12/7",
  "price": "4980",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "た",
  "name": "ダイヤドロイドワールド イービル帝国の野望",
  "maker": "エポック社",
  "releaseDate": "2001/12/7",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "な",
  "name": "ナムコミュージアム",
  "maker": "ナムコ",
  "releaseDate": "2001/12/7",
  "price": "3800",
  "genre": "アクション・シューティング・他",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "ま",
  "name": "マジカルバケーション",
  "maker": "任天堂",
  "releaseDate": "2001/12/7",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "も",
  "name": "モンスターファームアドバンス",
  "maker": "テクモ",
  "releaseDate": "2001/12/7",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "わ",
  "name": "ワールドアドバンスサッカー 勝利への道",
  "maker": "ハンズオン・エンタテインメント",
  "releaseDate": "2001/12/7",
  "price": "5200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "106",
  "ini": "か",
  "name": "学校をつくろう!!アドバンス",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/12/7",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "た",
  "name": "大戦略 For ゲームボーイアドバンス",
  "maker": "メディアカイト",
  "releaseDate": "2001/12/7",
  "price": "4980",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "こ",
  "name": "コラムスクラウン",
  "maker": "セガ",
  "releaseDate": "2001/12/13",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "し",
  "name": "実況ワールドサッカーポケット",
  "maker": "コナミ",
  "releaseDate": "2001/12/13",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "た",
  "name": "大麻雀。",
  "maker": "ホリ",
  "releaseDate": "2001/12/13",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "え",
  "name": "SK8 Tony Hawk's PRO SKATER 2",
  "maker": "サクセス",
  "releaseDate": "2001/12/14",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "さ",
  "name": "サンサーラナーガ1×2",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/12/14",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "す",
  "name": "スーパーマリオアドバンス2",
  "maker": "任天堂",
  "releaseDate": "2001/12/14",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "は",
  "name": "バトルネットワーク ロックマンエグゼ2",
  "maker": "カプコン",
  "releaseDate": "2001/12/14",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "し",
  "name": "上海アドバンス",
  "maker": "サンソフト",
  "releaseDate": "2001/12/14",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "い",
  "name": "ESPN Winter X Games snowboarding 2002",
  "maker": "コナミ",
  "releaseDate": "2001/12/20",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "え",
  "name": "エキサイティングバス",
  "maker": "コナミ",
  "releaseDate": "2001/12/20",
  "price": "5800",
  "genre": "釣り・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "118",
  "ini": "そ",
  "name": "ソニックアドバンス",
  "maker": "セガ",
  "releaseDate": "2001/12/20",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "と",
  "name": "ドラゴンクエスト・キャラクターズ トルネコの大冒険2アドバンス 不思議のダンジョン",
  "maker": "エニックス",
  "releaseDate": "2001/12/20",
  "price": "5980",
  "genre": "ダンジョン・RPG",
  "icNo": "1"
 },
 {
  "id": "120",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ6 エキスパート2",
  "maker": "コナミ",
  "releaseDate": "2001/12/20",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "れ",
  "name": "烈火の炎 -THE GAME-",
  "maker": "コナミ",
  "releaseDate": "2001/12/20",
  "price": "5800",
  "genre": "アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "む",
  "name": "MUTSU ウォータールーパームツ",
  "maker": "トミー",
  "releaseDate": "2001/12/21",
  "price": "5200",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "う",
  "name": "ウィザードリィ サマナー",
  "maker": "メディアリング",
  "releaseDate": "2001/12/21",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "124",
  "ini": "す",
  "name": "スウィートクッキーパイ",
  "maker": "カルチャーブレーン",
  "releaseDate": "2001/12/21",
  "price": "4980",
  "genre": "ストーリー・ミニゲーム・テーブル",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "す",
  "name": "スーパーパズルボブルアドバンス",
  "maker": "タイトー",
  "releaseDate": "2001/12/21",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "と",
  "name": "ドナルドダック アドバンス",
  "maker": "ユービーアイソフト",
  "releaseDate": "2001/12/21",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "ひ",
  "name": "ピンキーモンキータウン",
  "maker": "スターフィッシュ",
  "releaseDate": "2001/12/21",
  "price": "4800",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "128",
  "ini": "ふ",
  "name": "ブレス オブ ファイアⅡ 使命の子",
  "maker": "カプコン",
  "releaseDate": "2001/12/21",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "129",
  "ini": "き",
  "name": "機動天使エンジェリックレイヤー みさきと夢の天使達",
  "maker": "エポック社",
  "releaseDate": "2001/12/21",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "て",
  "name": "鉄拳アドバンス",
  "maker": "ナムコ",
  "releaseDate": "2001/12/21",
  "price": "5300",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "す",
  "name": "SLOT！PROアドバンス　宝船&大江戸桜吹雪2",
  "maker": "日本テレネット",
  "releaseDate": "2001/12/28",
  "price": "4800",
  "genre": "パチスロ",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "く",
  "name": "グランボ",
  "maker": "カプコン",
  "releaseDate": "2001/12/28",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "133",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズEX NEO BLOOD",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2002/1/1",
  "price": "5800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "は",
  "name": "パックマンコレクション",
  "maker": "ナムコ",
  "releaseDate": "2002/1/11",
  "price": "3800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "135",
  "ini": "く",
  "name": "グラディウス ジェネレーション",
  "maker": "コナミ",
  "releaseDate": "2002/1/17",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "136",
  "ini": "す",
  "name": "スナップキッズ",
  "maker": "エニックス",
  "releaseDate": "2002/1/17",
  "price": "5980",
  "genre": "RPG・カードバトル",
  "icNo": "1"
 },
 {
  "id": "137",
  "ini": "ゆ",
  "name": "幽霊屋敷の二十四時間",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/1/24",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "138",
  "ini": "き",
  "name": "ギルティギア ゼクス ADVANCE EDITION",
  "maker": "サミー",
  "releaseDate": "2002/1/25",
  "price": "5800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "139",
  "ini": "と",
  "name": "トマトアドベンチャー",
  "maker": "任天堂",
  "releaseDate": "2002/1/25",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "140",
  "ini": "あ",
  "name": "アニマルマニア どきどき相性チェック",
  "maker": "コナミ",
  "releaseDate": "2002/1/31",
  "price": "5800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "141",
  "ini": "は",
  "name": "ハイパースポーツ2002 WINTER",
  "maker": "コナミ",
  "releaseDate": "2002/1/31",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "142",
  "ini": "は",
  "name": "ハリー・ポッターと賢者の石",
  "maker": "エレクトロニック・アーツ・スクウェア",
  "releaseDate": "2002/1/31",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "143",
  "ini": "ほ",
  "name": "ボンバーマン MAX2 ボンバーマンバージョン",
  "maker": "ハドソン",
  "releaseDate": "2002/2/7",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "144",
  "ini": "ほ",
  "name": "ボンバーマン MAX2 マックスバージョン",
  "maker": "ハドソン",
  "releaseDate": "2002/2/7",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "ふ",
  "name": "BLACK BLACK ブラブラ",
  "maker": "カプコン",
  "releaseDate": "2002/2/8",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "146",
  "ini": "た",
  "name": "WTAツアーテニスポケット",
  "maker": "コナミ",
  "releaseDate": "2002/2/14",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "147",
  "ini": "め",
  "name": "メールでキュート",
  "maker": "コナミ",
  "releaseDate": "2002/2/14",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "148",
  "ini": "き",
  "name": "キャプテン翼 栄光の軌跡",
  "maker": "コナミ",
  "releaseDate": "2002/2/21",
  "price": "5800",
  "genre": "スポーツ・シミュレーション・カード",
  "icNo": "1"
 },
 {
  "id": "149",
  "ini": "と",
  "name": "どーもくんの不思議てれび",
  "maker": "任天堂",
  "releaseDate": "2002/2/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "し",
  "name": "Jリーグポケット2",
  "maker": "コナミ",
  "releaseDate": "2002/2/28",
  "price": "5800",
  "genre": "スポーツ・経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "151",
  "ini": "こ",
  "name": "ゴエモン ニューエイジ出動！",
  "maker": "コナミ",
  "releaseDate": "2002/2/28",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "152",
  "ini": "も",
  "name": "モンスターズ・インク",
  "maker": "トミー",
  "releaseDate": "2002/3/1",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "153",
  "ini": "こ",
  "name": "コロコロパズル ハッピィパネッチュ！",
  "maker": "任天堂",
  "releaseDate": "2002/3/8",
  "price": "4800",
  "genre": "パズル",
  "icNo": "17"
 },
 {
  "id": "154",
  "ini": "ふ",
  "name": "ふしぎの国のアンジェリーク",
  "maker": "コーエー",
  "releaseDate": "2002/3/8",
  "price": "5800",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "ち",
  "name": "沈黙の遺跡 エストポリス外伝",
  "maker": "タイトー",
  "releaseDate": "2002/3/8",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "156",
  "ini": "け",
  "name": "K-1 ポケットグランプリ",
  "maker": "コナミ",
  "releaseDate": "2002/3/14",
  "price": "5800",
  "genre": "スポーツ・対戦格闘",
  "icNo": "1"
 },
 {
  "id": "157",
  "ini": "か",
  "name": "川のぬし釣り5 不思議の森から",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2002/3/15",
  "price": "4800",
  "genre": "釣り・RPG",
  "icNo": "1"
 },
 {
  "id": "158",
  "ini": "く",
  "name": "GROOVE ADMENTURE RAVE 光と闇の大決戦",
  "maker": "コナミ",
  "releaseDate": "2002/3/20",
  "price": "5800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "159",
  "ini": "は",
  "name": "パワプロクンポケット4",
  "maker": "コナミ",
  "releaseDate": "2002/3/20",
  "price": "5800",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "160",
  "ini": "あ",
  "name": "アンジェリーク",
  "maker": "コーエー",
  "releaseDate": "2002/3/21",
  "price": "5800",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "161",
  "ini": "な",
  "name": "なかよしペットアドバンスシリーズ② かわいい仔犬",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/3/22",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "162",
  "ini": "し",
  "name": "実戦パチスロ必勝法！獣王アドバンス",
  "maker": "サミー",
  "releaseDate": "2002/3/22",
  "price": "4800",
  "genre": "パチスロ",
  "icNo": "1"
 },
 {
  "id": "163",
  "ini": "し",
  "name": "シャイニング・ソウル",
  "maker": "セガ",
  "releaseDate": "2002/3/28",
  "price": "5800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "164",
  "ini": "い",
  "name": "一石八鳥 これ1本で8種類！",
  "maker": "コナミ",
  "releaseDate": "2002/3/28",
  "price": "5800",
  "genre": "テーブル・ゲーム集",
  "icNo": "1"
 },
 {
  "id": "165",
  "ini": "か",
  "name": "神の記述 Illusion of the evil eyes",
  "maker": "コナミ",
  "releaseDate": "2002/3/28",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "166",
  "ini": "よ",
  "name": "妖怪道",
  "maker": "フウキ",
  "releaseDate": "2002/3/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "167",
  "ini": "か",
  "name": "カエルBバック",
  "maker": "角川書店",
  "releaseDate": "2002/3/29",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "168",
  "ini": "と",
  "name": "ドラえもん どこでもウォーカー",
  "maker": "エポック社",
  "releaseDate": "2002/3/29",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "169",
  "ini": "は",
  "name": "ハムスター倶楽部3",
  "maker": "ジョルダン",
  "releaseDate": "2002/3/29",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "ふ",
  "name": "ファイアーエムブレム 封印の剣",
  "maker": "任天堂",
  "releaseDate": "2002/3/29",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "ま",
  "name": "マジカル封神",
  "maker": "コーエー",
  "releaseDate": "2002/3/29",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "172",
  "ini": "し",
  "name": "新日本プロレスリング 闘魂烈伝アドバンス",
  "maker": "トミー",
  "releaseDate": "2002/3/29",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "173",
  "ini": "み",
  "name": "ミニモニ。ミカのハッピーモーニングchatty",
  "maker": "小学館ミュージックアンドデジタルエンタテイメント",
  "releaseDate": "2002/4/1",
  "price": "4800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "174",
  "ini": "て",
  "name": "デンキブロックス！",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/4/11",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "る",
  "name": "LUNAR レジェンド",
  "maker": "メディアリング",
  "releaseDate": "2002/4/12",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "176",
  "ini": "ひ",
  "name": "ひつじのキモチ。",
  "maker": "カプコン",
  "releaseDate": "2002/4/19",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "し",
  "name": "人生ゲーム アドバンス",
  "maker": "タカラ",
  "releaseDate": "2002/4/18",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "178",
  "ini": "く",
  "name": "黒ひげのくるっとジントリ",
  "maker": "トミー",
  "releaseDate": "2002/4/19",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "179",
  "ini": "く",
  "name": "黒ひげのゴルフしようよ",
  "maker": "トミー",
  "releaseDate": "2002/4/19",
  "price": "4800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "180",
  "ini": "た",
  "name": "大好きテディ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/4/19",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "181",
  "ini": "う",
  "name": "ウイ・イレ",
  "maker": "コナミ",
  "releaseDate": "2002/4/25",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "182",
  "ini": "て",
  "name": "テニスの王子様 ジーニアス・ボーイズ・アカデミー",
  "maker": "コナミ",
  "releaseDate": "2002/4/25",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "183",
  "ini": "す",
  "name": "SLOT！PRO2 アドバンス GOGOジャグラー&Newタイリョウ",
  "maker": "日本テレネット",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "パチスロ",
  "icNo": "1"
 },
 {
  "id": "184",
  "ini": "あ",
  "name": "アドバンスGT2",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "185",
  "ini": "お",
  "name": "おしゃべりインコ倶楽部",
  "maker": "アルファ・ユニット",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "す",
  "name": "スパイダーマン ミステリオの脅威",
  "maker": "サクセス",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "187",
  "ini": "て",
  "name": "テトリスワールド",
  "maker": "サクセス",
  "releaseDate": "2002/4/26",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "188",
  "ini": "ふ",
  "name": "ぷくぷく天然かいらんばん",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "ろ",
  "name": "ロックマンゼロ",
  "maker": "カプコン",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "190",
  "ini": "け",
  "name": "携帯電獣テレファング2　スピード",
  "maker": "スマイルソフト",
  "releaseDate": "2002/4/26",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "191",
  "ini": "け",
  "name": "携帯電獣テレファング2　パワー",
  "maker": "スマイルソフト",
  "releaseDate": "2002/4/26",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "192",
  "ini": "い",
  "name": "頭文字D Another Stage",
  "maker": "サミー",
  "releaseDate": "2002/4/26",
  "price": "5800",
  "genre": "アドベンチャー・レース",
  "icNo": "1"
 },
 {
  "id": "193",
  "ini": "に",
  "name": "日本プロ麻雀連盟公認　徹萬アドバンス 免許皆伝シリーズ",
  "maker": "加賀テック",
  "releaseDate": "2002/4/26",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "194",
  "ini": "こ",
  "name": "コナミアーケードゲームコレクション",
  "maker": "コナミ",
  "releaseDate": "2002/5/2",
  "price": "4800",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "195",
  "ini": "ふ",
  "name": "フォーメーションサッカー2002",
  "maker": "スパイク",
  "releaseDate": "2002/5/2",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "も",
  "name": "モトクロスマニアックスADVANCE",
  "maker": "コナミ",
  "releaseDate": "2002/5/2",
  "price": "4800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "197",
  "ini": "お",
  "name": "おしゃれプリンセス",
  "maker": "カルチャーブレーン",
  "releaseDate": "2002/5/24",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "198",
  "ini": "と",
  "name": "トータルサッカーアドバンス",
  "maker": "ユービーアイソフト",
  "releaseDate": "2002/5/31",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "199",
  "ini": "と",
  "name": "とっとこハム太郎3 ラブラブ大冒険でちゅ",
  "maker": "任天堂",
  "releaseDate": "2002/5/31",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "200",
  "ini": "き",
  "name": "キャッスルヴァニア 白夜の協奏曲",
  "maker": "コナミ",
  "releaseDate": "2002/6/6",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "201",
  "ini": "ふ",
  "name": "ファミリーテニスアドバンス",
  "maker": "ナムコ",
  "releaseDate": "2002/6/14",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "202",
  "ini": "か",
  "name": "ガチンコプロ野球",
  "maker": "ナウプロダクション",
  "releaseDate": "2002/6/21",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "203",
  "ini": "ふ",
  "name": "V-RALLY3",
  "maker": "アタリ",
  "releaseDate": "2002/6/27",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "204",
  "ini": "は",
  "name": "花札トランプ麻雀 デパチカ 和洋中",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/6/27",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "205",
  "ini": "は",
  "name": "爆転シュート ベイブレード2002 いくぜ！激闘！超磁力バトル",
  "maker": "ブロッコリー",
  "releaseDate": "2002/6/27",
  "price": "4800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "206",
  "ini": "な",
  "name": "ナチュラル2 デュオ",
  "maker": "オメガ・プロジェクト",
  "releaseDate": "2002/6/28",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "207",
  "ini": "か",
  "name": "かまいたちの夜 アドバンス",
  "maker": "チュンソフト",
  "releaseDate": "2002/6/28",
  "price": "4800",
  "genre": "サウンドノベル",
  "icNo": "1"
 },
 {
  "id": "208",
  "ini": "か",
  "name": "かわいいペットショップ物語3",
  "maker": "パシフィック・センチュリー・サイバーワークス・ジャパン",
  "releaseDate": "2002/6/28",
  "price": "4800",
  "genre": "育成・RPG",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "ふ",
  "name": "ファミスタ アドバンス",
  "maker": "ナムコ",
  "releaseDate": "2002/6/28",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "210",
  "ini": "お",
  "name": "黄金の太陽 失われし時代",
  "maker": "任天堂",
  "releaseDate": "2002/6/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "211",
  "ini": "さ",
  "name": "ザ・ピンボール・オブ・ザ・デッド",
  "maker": "セガ",
  "releaseDate": "2002/7/4",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "212",
  "ini": "も",
  "name": "モンスターゲート",
  "maker": "コナミ",
  "releaseDate": "2002/7/4",
  "price": "4800",
  "genre": "ダンジョン・RPG",
  "icNo": "1"
 },
 {
  "id": "213",
  "ini": "の",
  "name": "信長異聞",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/7/4",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "214",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ7 決闘都市伝説",
  "maker": "コナミ",
  "releaseDate": "2002/7/4",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "215",
  "ini": "さ",
  "name": "さくらももこのウキウキカーニバル",
  "maker": "任天堂",
  "releaseDate": "2002/7/5",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "216",
  "ini": "は",
  "name": "ハロボッツ ロボヒーローバトリング!!",
  "maker": "サンライズインタラクティブ",
  "releaseDate": "2002/7/5",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "217",
  "ini": "は",
  "name": "はめパメ 東京ミュウミュウ",
  "maker": "タカラ",
  "releaseDate": "2002/7/11",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "218",
  "ini": "て",
  "name": "ディスニースポーツ：サッカー",
  "maker": "コナミ",
  "releaseDate": "2002/7/18",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "219",
  "ini": "は",
  "name": "ハイヒートメジャーリーグベースボール2003",
  "maker": "タカラ",
  "releaseDate": "2002/7/18",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "220",
  "ini": "く",
  "name": "クラッシュ・バンディクー アドバンス",
  "maker": "コナミ",
  "releaseDate": "2002/7/18",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "221",
  "ini": "ひ",
  "name": "ヒカルの碁2",
  "maker": "コナミ",
  "releaseDate": "2002/7/18",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "222",
  "ini": "ひ",
  "name": "ピノビィー&フィービィー",
  "maker": "ハドソン",
  "releaseDate": "2002/7/18",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "223",
  "ini": "ほ",
  "name": "Hot Wheels アドバンス",
  "maker": "アルトロン",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "224",
  "ini": "お",
  "name": "お花屋さん物語GBA いやし系お花屋さん育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "225",
  "ini": "と",
  "name": "ときめき夢シリーズ① お花屋さんになろう！",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "その他・シミュレーション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "226",
  "ini": "は",
  "name": "ハムスターパラダイス あどばんちゅ",
  "maker": "アトラス",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "227",
  "ini": "ふ",
  "name": "ファイナルファイヤープロレスリング 夢の団体運営！",
  "maker": "スパイク",
  "releaseDate": "2002/7/19",
  "price": "5980",
  "genre": "経営・シミュレーション・スポーツ",
  "icNo": "1"
 },
 {
  "id": "228",
  "ini": "め",
  "name": "メダロットG カブトバージョン",
  "maker": "ナツメ",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "アクション・対戦格闘",
  "icNo": "1"
 },
 {
  "id": "229",
  "ini": "め",
  "name": "メダロットG クワガタバージョン",
  "maker": "ナツメ",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "アクション・対戦格闘",
  "icNo": "1"
 },
 {
  "id": "230",
  "ini": "し",
  "name": "勝負師伝説 哲也 甦る伝説",
  "maker": "アテナ",
  "releaseDate": "2002/7/19",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "ち",
  "name": "超魔界村R",
  "maker": "カプコン",
  "releaseDate": "2002/7/19",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "232",
  "ini": "あ",
  "name": "アイス・エイジ",
  "maker": "ユービーアイソフト",
  "releaseDate": "2002/7/20",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "233",
  "ini": "て",
  "name": "ディズニースポーツ：アメリカンフットボール",
  "maker": "コナミ",
  "releaseDate": "2002/7/25",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "234",
  "ini": "て",
  "name": "ディズニースポーツ：スケートボーディング",
  "maker": "コナミ",
  "releaseDate": "2002/7/25",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "235",
  "ini": "こ",
  "name": "ゴーストトラップ",
  "maker": "アイドス・インタラクティブ",
  "releaseDate": "2002/7/25",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "236",
  "ini": "は",
  "name": "バブルボブル OLD&NEW",
  "maker": "メディアカイト",
  "releaseDate": "2002/7/25",
  "price": "4800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "237",
  "ini": "ふ",
  "name": "ブイマスタークロス",
  "maker": "サクセス",
  "releaseDate": "2002/7/26",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "238",
  "ini": "か",
  "name": "カスタムロボGX",
  "maker": "任天堂",
  "releaseDate": "2002/7/26",
  "price": "4800",
  "genre": "アドベンチャー・アクション",
  "icNo": "1"
 },
 {
  "id": "239",
  "ini": "か",
  "name": "ガチャステ！ダイナデバイス ブルー",
  "maker": "スマイルソフト",
  "releaseDate": "2002/7/26",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "240",
  "ini": "か",
  "name": "ガチャステ！ダイナデバイス レッド",
  "maker": "スマイルソフト",
  "releaseDate": "2002/7/26",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "241",
  "ini": "し",
  "name": "シャーマンキング 超・占事略決2",
  "maker": "キングレコード",
  "releaseDate": "2002/7/26",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "242",
  "ini": "す",
  "name": "スーパーロボット大戦R",
  "maker": "バンプレスト",
  "releaseDate": "2002/8/2",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "243",
  "ini": "す",
  "name": "スペースインベーダーEX",
  "maker": "タイトー",
  "releaseDate": "2002/8/2",
  "price": "4200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "244",
  "ini": "か",
  "name": "風のクロノアG2 ドリームチャンプ・トーナメント",
  "maker": "ナムコ",
  "releaseDate": "2002/8/6",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "245",
  "ini": "く",
  "name": "グレイテストナイン",
  "maker": "セガ",
  "releaseDate": "2002/8/8",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "246",
  "ini": "こ",
  "name": "コンバットチョロQ アドバンス大作戦",
  "maker": "タカラ",
  "releaseDate": "2002/8/8",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "247",
  "ini": "ふ",
  "name": "プロ野球チームをつくろう！アドバンス",
  "maker": "セガ",
  "releaseDate": "2002/8/8",
  "price": "5800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "248",
  "ini": "な",
  "name": "なかよしペットアドバンスシリーズ③ かわいい子猫",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/8/9",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "249",
  "ini": "み",
  "name": "ミッキーとミニーのマジカルクエスト",
  "maker": "任天堂",
  "releaseDate": "2002/8/9",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "250",
  "ini": "ろ",
  "name": "ロックマン&フォルテ",
  "maker": "カプコン",
  "releaseDate": "2002/8/10",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "251",
  "ini": "み",
  "name": "ミスタードリラーエース ふしぎなパクテリア",
  "maker": "ナムコ",
  "releaseDate": "2002/8/23",
  "price": "4800",
  "genre": "パズル・アクション",
  "icNo": "1"
 },
 {
  "id": "252",
  "ini": "は",
  "name": "遙かなる時空の中で",
  "maker": "コーエー",
  "releaseDate": "2002/8/23",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "253",
  "ini": "ひ",
  "name": "姫騎士物語 Princess Blue",
  "maker": "トンキンハウス",
  "releaseDate": "2002/8/29",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "254",
  "ini": "ふ",
  "name": "BLACK/MATRIX ZERO",
  "maker": "NECインターチャネル",
  "releaseDate": "2002/8/30",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "255",
  "ini": "し",
  "name": "Jリーグ プロサッカークラブをつくろう！アドバンス",
  "maker": "セガ",
  "releaseDate": "2002/9/5",
  "price": "5800",
  "genre": "育成・シミュレーション・スポーツ",
  "icNo": "1"
 },
 {
  "id": "256",
  "ini": "て",
  "name": "伝説のスタフィー",
  "maker": "任天堂",
  "releaseDate": "2002/9/6",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "257",
  "ini": "す",
  "name": "ズーキューブ",
  "maker": "アクレイムジャパン",
  "releaseDate": "2002/9/12",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "258",
  "ini": "き",
  "name": "ギャラクシーエンジェル ゲームボーイアドバンス 盛りだくさん天使のフルコース おかわり自由",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2002/9/13",
  "price": "5800",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "259",
  "ini": "さ",
  "name": "サムライエボリューション 桜国ガイスト",
  "maker": "エニックス",
  "releaseDate": "2002/9/20",
  "price": "5980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "260",
  "ini": "す",
  "name": "スーパーマリオアドバンス3",
  "maker": "任天堂",
  "releaseDate": "2002/9/20",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "261",
  "ini": "く",
  "name": "GROOVE ADVENTURE RAVE 光と闇の大決戦2",
  "maker": "コナミ",
  "releaseDate": "2002/9/26",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "262",
  "ini": "え",
  "name": "エアフォースデルタⅡ",
  "maker": "コナミ",
  "releaseDate": "2002/9/26",
  "price": "4800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "263",
  "ini": "え",
  "name": "エッグマニア つかんで！まわして！どっすんぱず～る!!",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2002/9/27",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "264",
  "ini": "す",
  "name": "ストリートファイターZERO3アッパー",
  "maker": "カプコン",
  "releaseDate": "2002/9/27",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "265",
  "ini": "ち",
  "name": "ちょびっツ for Gameboy Advance アタシだけのヒト",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2002/9/27",
  "price": "5800",
  "genre": "育成・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "266",
  "ini": "ふ",
  "name": "ファンシーポケット",
  "maker": "ジョルダン",
  "releaseDate": "2002/9/27",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "267",
  "ini": "め",
  "name": "メタルガン・スリンガー",
  "maker": "アットマーク",
  "releaseDate": "2002/9/27",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "268",
  "ini": "み",
  "name": "南の海のオデッセイ",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/10/3",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "269",
  "ini": "な",
  "name": "なかよし幼稚園 すこやか園児育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2002/10/4",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "270",
  "ini": "し",
  "name": "Jリーグ ウイニングイレブン アドバンス2002",
  "maker": "コナミ",
  "releaseDate": "2002/10/10",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "271",
  "ini": "さ",
  "name": "SILENT SCOPE",
  "maker": "コナミ",
  "releaseDate": "2002/10/17",
  "price": "4800",
  "genre": "ガン・シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "272",
  "ini": "こ",
  "name": "コロッケ！夢のバンカーサバイバル！",
  "maker": "コナミ",
  "releaseDate": "2002/10/17",
  "price": "4800",
  "genre": "アドベンチャー・アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "273",
  "ini": "き",
  "name": "逆転裁判2",
  "maker": "カプコン",
  "releaseDate": "2002/10/18",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "274",
  "ini": "ほ",
  "name": "ボンバーマンジェッターズ 伝説のボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "2002/10/24",
  "price": "4800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "275",
  "ini": "も",
  "name": "Moto GP",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/10/25",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "276",
  "ini": "か",
  "name": "カードパーティ",
  "maker": "メディアカイト",
  "releaseDate": "2002/10/25",
  "price": "3800",
  "genre": "テーブル・ゲーム集",
  "icNo": "1"
 },
 {
  "id": "277",
  "ini": "て",
  "name": "デ・ジ・キャラット でじこミュニケーション",
  "maker": "ブロッコリー",
  "releaseDate": "2002/10/25",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "278",
  "ini": "て",
  "name": "テイルズ オブ ザ ワールド なりきりダンジョン2",
  "maker": "ナムコ",
  "releaseDate": "2002/10/25",
  "price": "4800",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "279",
  "ini": "へ",
  "name": "ベストプレープロ野球",
  "maker": "エンターブレイン",
  "releaseDate": "2002/10/25",
  "price": "5800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "280",
  "ini": "も",
  "name": "モンスターファームアドバンス2",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2002/10/25",
  "price": "5800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "281",
  "ini": "ほ",
  "name": "星のカービィ 夢の泉デラックス",
  "maker": "任天堂",
  "releaseDate": "2002/10/25",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "282",
  "ini": "し",
  "name": "シルクとコットン",
  "maker": "キキ",
  "releaseDate": "2002/10/31",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "283",
  "ini": "ひ",
  "name": "ビーストシューター めざせ闘獣王！",
  "maker": "コナミ",
  "releaseDate": "2002/10/31",
  "price": "4800",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "284",
  "ini": "ふ",
  "name": "ファンタジックメルヘン ケーキ屋さん物語+動物キャラナビ占い個性心理学",
  "maker": "カルチャーブレーン",
  "releaseDate": "2002/11/1",
  "price": "4800",
  "genre": "アドベンチャー・その他",
  "icNo": "1"
 },
 {
  "id": "285",
  "ini": "ta",
  "name": "DAN DOH!! Xi",
  "maker": "ネットビレッジ",
  "releaseDate": "2002/11/14",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "286",
  "ini": "こ",
  "name": "魂斗羅ハードスピリッツ",
  "maker": "コナミ",
  "releaseDate": "2002/11/14",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "287",
  "ini": "ふ",
  "name": "From TV animation ONE PIECE ナナツ島の大秘宝",
  "maker": "バンプレスト",
  "releaseDate": "2002/11/15",
  "price": "4800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "288",
  "ini": "す",
  "name": "スキャンハンター 千年怪魚を追え！",
  "maker": "パシフィック・センチュリー・サイバーワークス・ジャパン",
  "releaseDate": "2002/11/15",
  "price": "4800",
  "genre": "釣り・アクション",
  "icNo": "1"
 },
 {
  "id": "289",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 闇の書",
  "maker": "アトラス",
  "releaseDate": "2002/11/15",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "290",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 光の書",
  "maker": "アトラス",
  "releaseDate": "2002/11/15",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "291",
  "ini": "ほ",
  "name": "ポケットモンスター サファイア",
  "maker": "任天堂",
  "releaseDate": "2002/11/21",
  "price": "4800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "292",
  "ini": "ほ",
  "name": "ポケットモンスター ルビー",
  "maker": "任天堂",
  "releaseDate": "2002/11/21",
  "price": "4800",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "293",
  "ini": "す",
  "name": "スーパーロボット大戦 ORIGINAL GENERATION",
  "maker": "バンプレスト",
  "releaseDate": "2002/11/22",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "294",
  "ini": "と",
  "name": "ドキドキクッキングシリーズ① こむぎちゃんのハッピーケーキ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/11/22",
  "price": "4800",
  "genre": "クッキング・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "295",
  "ini": "は",
  "name": "ハリー・ポッターと秘密の部屋",
  "maker": "エレクトロニック・アーツ・スクウェア",
  "releaseDate": "2002/11/23",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "296",
  "ini": "け",
  "name": "K-1ポケットグランプリ2",
  "maker": "コナミ",
  "releaseDate": "2002/11/28",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "297",
  "ini": "ち",
  "name": "チョロQアドバンス2",
  "maker": "タカラ",
  "releaseDate": "2002/11/28",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "298",
  "ini": "し",
  "name": "実況ワールドサッカーポケット2",
  "maker": "コナミ",
  "releaseDate": "2002/11/28",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "299",
  "ini": "り",
  "name": "リトルバスターQ",
  "maker": "トミー",
  "releaseDate": "2002/11/29",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "300",
  "ini": "ひ",
  "name": "彼岸花",
  "maker": "アテナ",
  "releaseDate": "2002/11/29",
  "price": "4800",
  "genre": "サウンドノベル",
  "icNo": "1"
 },
 {
  "id": "301",
  "ini": "は",
  "name": "バス釣りしようぜ！トーナメントは戦略だ！",
  "maker": "コナミ",
  "releaseDate": "2002/12/5",
  "price": "4800",
  "genre": "釣り・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "302",
  "ini": "う",
  "name": "ウッディ・ウッドペッカー クレイジーキャッスル5",
  "maker": "コナミ",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "303",
  "ini": "き",
  "name": "キン肉マンⅡ世 正義超人への道",
  "maker": "バンプレスト",
  "releaseDate": "2002/12/6",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "304",
  "ini": "く",
  "name": "くるりんパラダイス",
  "maker": "任天堂",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "アクション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "305",
  "ini": "し",
  "name": "シルバニアファミリー4 めぐる季節のタペストリー",
  "maker": "エポック社",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "306",
  "ini": "た",
  "name": "ダービースタリオンアドバンス",
  "maker": "エンターブレイン",
  "releaseDate": "2002/12/6",
  "price": "5800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "307",
  "ini": "と",
  "name": "トゥームレイダー：プロフェシー",
  "maker": "ユービーアイソフト",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "308",
  "ini": "は",
  "name": "バトルネットワーク ロックマンエグゼ3",
  "maker": "カプコン",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "309",
  "ini": "は",
  "name": "バルダーダッシュEX",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "パズル・アクション",
  "icNo": "1"
 },
 {
  "id": "310",
  "ini": "わ",
  "name": "ユメミちゃんのなりたいシリーズ③ わたしのメイクサロン",
  "maker": "ラディック",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "メイク・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "311",
  "ini": "し",
  "name": "志村けんのバカ殿様 爆笑天下統一ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2002/12/6",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "312",
  "ini": "は",
  "name": "爆転シュート ベイブレード2002 激戦！チームバトル!!黄龍の章 ダイチ編",
  "maker": "ブロッコリー",
  "releaseDate": "2002/12/6",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "313",
  "ini": "は",
  "name": "爆転シュート ベイブレード2002 激戦！チームバトル!!青龍の章 タカオ編",
  "maker": "ブロッコリー",
  "releaseDate": "2002/12/6",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "314",
  "ini": "て",
  "name": "テニスの王子様 Aim at The Victory！",
  "maker": "コナミ",
  "releaseDate": "2002/12/12",
  "price": "4800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "315",
  "ini": "は",
  "name": "はじめの一歩 THE FIGHTING！",
  "maker": "エンターテインメント・ソフトウェア・パブリッシング",
  "releaseDate": "2002/12/12",
  "price": "5500",
  "genre": "スポーツ・アクション",
  "icNo": "1"
 },
 {
  "id": "316",
  "ini": "み",
  "name": "ミニモニ。おねがい☆お星さま！",
  "maker": "コナミ",
  "releaseDate": "2002/12/12",
  "price": "4800",
  "genre": "その他・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "317",
  "ini": "あ",
  "name": "アオ・ゾーラと仲間たち 夢の冒険",
  "maker": "エム・ティー･オー",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "318",
  "ini": "く",
  "name": "クロノアヒーローズ 伝説のスターメダル",
  "maker": "ナムコ",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "319",
  "ini": "し",
  "name": "シャーマンキング 超・占事略決3",
  "maker": "キングレコード",
  "releaseDate": "2002/12/13",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "320",
  "ini": "た",
  "name": "ダライアスR",
  "maker": "パシフィック・センチュリー・サイバーワークス・ジャパン",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "ち",
  "name": "チョコボランド A Game Of Dice",
  "maker": "スクウェア",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "322",
  "ini": "ま",
  "name": "マジャイネーション",
  "maker": "エポック社",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "323",
  "ini": "も",
  "name": "モンスターメーカー4 キラーダイス",
  "maker": "サクセス",
  "releaseDate": "2002/12/13",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "324",
  "ini": "も",
  "name": "モンスターメーカー4 フラッシュカード",
  "maker": "サクセス",
  "releaseDate": "2002/12/13",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "325",
  "ini": "い",
  "name": "犬っこ倶楽部 福丸の大冒険",
  "maker": "ジョルダン",
  "releaseDate": "2002/12/13",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "326",
  "ini": "て",
  "name": "ディズニースポーツ：バスケットボール",
  "maker": "コナミ",
  "releaseDate": "2002/12/19",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "327",
  "ini": "せ",
  "name": "SEGA RALLY",
  "maker": "セガ",
  "releaseDate": "2002/12/19",
  "price": "5800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "328",
  "ini": "そ",
  "name": "ソニックアドバンス2",
  "maker": "セガ",
  "releaseDate": "2002/12/19",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "329",
  "ini": "ほ",
  "name": "ボボボーボ・ボーボボ 奥義87.5爆烈鼻毛真拳",
  "maker": "ハドソン",
  "releaseDate": "2002/12/19",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "330",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！ 黄金マラカスの伝説",
  "maker": "コナミ",
  "releaseDate": "2002/12/19",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "331",
  "ini": "あ",
  "name": "アクロバットキッズ",
  "maker": "メトロ・スリーディジャパン",
  "releaseDate": "2002/12/20",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "332",
  "ini": "え",
  "name": "エレベーターアクション OLD&NEW",
  "maker": "メディアカイト",
  "releaseDate": "2002/12/20",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "333",
  "ini": "お",
  "name": "おしゃれプリンセス2 + 動物キャラナビ占い個性心理学",
  "maker": "カルチャーブレーン",
  "releaseDate": "2002/12/20",
  "price": "4800",
  "genre": "テーブル・その他",
  "icNo": "1"
 },
 {
  "id": "334",
  "ini": "ま",
  "name": "まんが家デビュー物語 お絵描きソフト&まんが家育成ゲーム！",
  "maker": "TDKコア",
  "releaseDate": "2002/12/20",
  "price": "4800",
  "genre": "育成・シミュレーション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "335",
  "ini": "う",
  "name": "宇宙大作戦チョコベーダー ウチュウからの侵略者",
  "maker": "ナムコ",
  "releaseDate": "2002/12/20",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "と",
  "name": "闘魂ヒート",
  "maker": "パシフィック・センチュリー・サイバーワークス・ジャパン",
  "releaseDate": "2002/12/20",
  "price": "5800",
  "genre": "ストーリー・スポーツ",
  "icNo": "1"
 },
 {
  "id": "337",
  "ini": "は",
  "name": "ハムスター物語3 GBA",
  "maker": "カルチャーブレーン",
  "releaseDate": "2002/12/24",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "338",
  "ini": "て",
  "name": "デュアルブレード",
  "maker": "メトロ・スリーディジャパン",
  "releaseDate": "2002/12/25",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "339",
  "ini": "す",
  "name": "スパイロ アドバンス",
  "maker": "コナミ",
  "releaseDate": "2002/12/26",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "340",
  "ini": "さ",
  "name": "SAMURAI DEEPER KYO",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2002/12/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "341",
  "ini": "せ",
  "name": "全日本少年サッカー大会2　めざせ日本一！",
  "maker": "サクセス",
  "releaseDate": "2002/12/27",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "342",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズEX2 Howling Blood",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2003/1/1",
  "price": "5800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "343",
  "ini": "き",
  "name": "きせっこぐるみぃ チェスティとぬいぐるみたちの魔法の冒険",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/1/1",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "344",
  "ini": "え",
  "name": "エレミックス！",
  "maker": "シムス",
  "releaseDate": "2003/1/3",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "345",
  "ini": "ふ",
  "name": "不思議の国のアリス",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2003/1/9",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "346",
  "ini": "こ",
  "name": "ことばのパズル もじぴったんアドバンス",
  "maker": "ナムコ",
  "releaseDate": "2003/1/10",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "347",
  "ini": "て",
  "name": "ディズニースポーツ：スノーボーディング",
  "maker": "コナミ",
  "releaseDate": "2003/1/16",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "348",
  "ini": "む",
  "name": "ムゲンボーグ",
  "maker": "コナミ",
  "releaseDate": "2003/1/16",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "349",
  "ini": "は",
  "name": "パワプロクンポケット5",
  "maker": "コナミ",
  "releaseDate": "2003/1/23",
  "price": "4800",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "350",
  "ini": "い",
  "name": "犬夜叉 奈落の罠！迷いの森の招待状",
  "maker": "エイベックス",
  "releaseDate": "2003/1/23",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "351",
  "ini": "ま",
  "name": "マリー、エリー&アニスのアトリエ そよ風からの伝言",
  "maker": "バンプレスト",
  "releaseDate": "2003/1/24",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "352",
  "ini": "て",
  "name": "ディズニースポーツ：モトクロス",
  "maker": "コナミ",
  "releaseDate": "2003/2/13",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "353",
  "ini": "ふ",
  "name": "ファイナルファンタジータクティクスアドバンス",
  "maker": "スクウェア",
  "releaseDate": "2003/2/14",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "354",
  "ini": "め",
  "name": "メトロイドフュージョン",
  "maker": "任天堂",
  "releaseDate": "2003/2/14",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "355",
  "ini": "ろ",
  "name": "ロード・オブ・ザ・リング 二つの塔",
  "maker": "エレクトロニック・アーツ・スクウェア",
  "releaseDate": "2003/2/14",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "356",
  "ini": "て",
  "name": "テニスの王子様2003 COOLBLUE",
  "maker": "コナミ",
  "releaseDate": "2003/2/20",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "357",
  "ini": "て",
  "name": "テニスの王子様2003 PASSIONRED",
  "maker": "コナミ",
  "releaseDate": "2003/2/20",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "358",
  "ini": "ろ",
  "name": "ロードランナー",
  "maker": "サクセス",
  "releaseDate": "2003/2/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "359",
  "ini": "ほ",
  "name": "ホイッスル！ 第37回東京都中学校総合体育サッカー大会",
  "maker": "コナミ",
  "releaseDate": "2003/2/27",
  "price": "4800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "360",
  "ini": "せ",
  "name": "戦国革命外伝",
  "maker": "コナミ",
  "releaseDate": "2003/2/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "361",
  "ini": "て",
  "name": "テイルズ オブ ザ ワールド サモナーズ リネージ",
  "maker": "ナムコ",
  "releaseDate": "2003/3/7",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "362",
  "ini": "せ",
  "name": "ゼルダの伝説 神々のトライフォース&4つの剣",
  "maker": "任天堂",
  "releaseDate": "2003/3/14",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "363",
  "ini": "と",
  "name": "ドキドキクッキングシリーズ② グルメキッチン ステキなお弁当",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/3/14",
  "price": "4800",
  "genre": "クッキング・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "364",
  "ini": "け",
  "name": "ゲットバッカーズ奪還屋 メトロポリス奪還作戦！",
  "maker": "コナミ",
  "releaseDate": "2003/3/20",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "365",
  "ini": "し",
  "name": "SIMPLE2960ともだちシリーズ Vol.1 THE テーブルゲームコレクション 麻雀・将棋・花札・リバーシ",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2003/3/20",
  "price": "2960",
  "genre": "テーブル・ゲーム集",
  "icNo": "1"
 },
 {
  "id": "366",
  "ini": "し",
  "name": "SIMPLE2960ともだちシリーズ Vol.2 THE ブロックくずし",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2003/3/20",
  "price": "2960",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "367",
  "ini": "し",
  "name": "シスタープリンセス リピュア",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2003/3/20",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "368",
  "ini": "け",
  "name": "激闘伝説ノア ドリームマネージメント",
  "maker": "ゲームビレッジ",
  "releaseDate": "2003/3/20",
  "price": "5800",
  "genre": "スポーツ・経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "369",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ8 破滅の大邪神",
  "maker": "コナミ",
  "releaseDate": "2003/3/20",
  "price": "4800",
  "genre": "RPG・カードバトル",
  "icNo": "1"
 },
 {
  "id": "370",
  "ini": "め",
  "name": "メイド イン ワリオ",
  "maker": "任天堂",
  "releaseDate": "2003/3/21",
  "price": "4800",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "371",
  "ini": "た",
  "name": "Dancing Sowrd 閃光",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/3/28",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "372",
  "ini": "ふ",
  "name": "From TV animation ONE PIECE めざせ！キングオブベリー",
  "maker": "バンプレスト",
  "releaseDate": "2003/3/28",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "373",
  "ini": "は",
  "name": "パズル&探偵コレクション",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/3/28",
  "price": "4800",
  "genre": "アドベンチャー・パズル・ゲーム集",
  "icNo": "1"
 },
 {
  "id": "374",
  "ini": "は",
  "name": "バトルネットワーク ロックマンエグゼ3 BLACK",
  "maker": "カプコン",
  "releaseDate": "2003/3/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "375",
  "ini": "し",
  "name": "真・女神転生",
  "maker": "アトラス",
  "releaseDate": "2003/3/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "376",
  "ini": "と",
  "name": "ドラゴンクエストモンスターズ キャラバンハート",
  "maker": "エニックス",
  "releaseDate": "2003/3/29",
  "price": "5980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "377",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ インターナショナル ワールドワイドエディション",
  "maker": "コナミ",
  "releaseDate": "2003/4/17",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "378",
  "ini": "そ",
  "name": "ZOIDS SAGAⅡ",
  "maker": "トミー",
  "releaseDate": "2003/4/18",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "379",
  "ini": "え",
  "name": "エンジェルコレクション めざせ！学園のファッションリーダー",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/4/18",
  "price": "4800",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "380",
  "ini": "め",
  "name": "メダロット弐CORE カブト",
  "maker": "ナツメ",
  "releaseDate": "2003/4/18",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "381",
  "ini": "め",
  "name": "メダロット弐CORE クワガタ",
  "maker": "ナツメ",
  "releaseDate": "2003/4/18",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "382",
  "ini": "ほ",
  "name": "牧場物語 ミネラルタウンのなかまたち",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2003/4/18",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "383",
  "ini": "は",
  "name": "HUNTER×HUNTER みんな友だち大作戦！",
  "maker": "コナミ",
  "releaseDate": "2003/4/24",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "384",
  "ini": "せ",
  "name": "ZERO ONE",
  "maker": "フウキ",
  "releaseDate": "2003/4/24",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "385",
  "ini": "ほ",
  "name": "冒険遊記プラスターワールド プラストオンGP",
  "maker": "タカラ",
  "releaseDate": "2003/4/24",
  "price": "5800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "386",
  "ini": "ほ",
  "name": "冒険遊記プラスターワールド 伝説のプラストゲート",
  "maker": "タカラ",
  "releaseDate": "2003/4/24",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "387",
  "ini": "ま",
  "name": "魔法のパンプキン アンとグレッグの大冒険",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/4/24",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "388",
  "ini": "あ",
  "name": "RPGツクール アドバンス",
  "maker": "エンターブレイン",
  "releaseDate": "2003/4/25",
  "price": "5800",
  "genre": "RPG制作",
  "icNo": "1"
 },
 {
  "id": "389",
  "ini": "あ",
  "name": "あずまんが大王 アドバンス",
  "maker": "キングレコード",
  "releaseDate": "2003/4/25",
  "price": "5800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "390",
  "ini": "さ",
  "name": "サモンナイト クラフトソード物語",
  "maker": "バンプレスト",
  "releaseDate": "2003/4/25",
  "price": "5800",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "391",
  "ini": "ふ",
  "name": "ファイアーエムブレム 烈火の剣",
  "maker": "任天堂",
  "releaseDate": "2003/4/25",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "392",
  "ini": "な",
  "name": "NARUTO -ナルト- 忍術全開！最強忍者 大結集",
  "maker": "トミー",
  "releaseDate": "2003/5/1",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "393",
  "ini": "ろ",
  "name": "ロックマンゼロ2",
  "maker": "カプコン",
  "releaseDate": "2003/5/2",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "394",
  "ini": "き",
  "name": "キャッスルヴァニア 暁月の円舞曲",
  "maker": "コナミ",
  "releaseDate": "2003/5/8",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "395",
  "ini": "と",
  "name": "とっとこハム太郎4 にじいろ大行進でちゅ",
  "maker": "任天堂",
  "releaseDate": "2003/5/23",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "396",
  "ini": "は",
  "name": "ハムスター物語コレクション",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/5/23",
  "price": "4800",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "397",
  "ini": "ふ",
  "name": "フロッガー 魔法の国の大冒険",
  "maker": "コナミ",
  "releaseDate": "2003/6/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "398",
  "ini": "も",
  "name": "モンスターゲート 大いなるダンジョン 封印のオーブ",
  "maker": "コナミ",
  "releaseDate": "2003/6/12",
  "price": "4800",
  "genre": "ダンジョン・RPG",
  "icNo": "1"
 },
 {
  "id": "399",
  "ini": "ま",
  "name": "MOTHER 1+2",
  "maker": "任天堂",
  "releaseDate": "2003/6/20",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "400",
  "ini": "め",
  "name": "メタルマックス2 改",
  "maker": "ナウプロダクション",
  "releaseDate": "2003/6/20",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "401",
  "ini": "み",
  "name": "みんなの飼育シリーズ ぼくのカブト・クワガタ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/6/27",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "402",
  "ini": "は",
  "name": "ハチエモン",
  "maker": "ナムコ",
  "releaseDate": "2003/7/4",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "403",
  "ini": "け",
  "name": "GET！ボクのムシつかまえて",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2003/7/11",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "404",
  "ini": "す",
  "name": "スーパーマリオアドバンス4",
  "maker": "任天堂",
  "releaseDate": "2003/7/11",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "405",
  "ini": "な",
  "name": "なかよしペット アドバンスシリーズ4 かわいい仔犬ミニ わんこと遊ぼう!!小型犬",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/7/11",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "406",
  "ini": "は",
  "name": "ハムスターパラダイス ピュアハート",
  "maker": "アトラス",
  "releaseDate": "2003/7/11",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "407",
  "ini": "こ",
  "name": "コロッケ！2 闇のバンクとバン女王",
  "maker": "コナミ",
  "releaseDate": "2003/7/17",
  "price": "4980",
  "genre": "アドベンチャー・アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "408",
  "ini": "そ",
  "name": "ソニックピンボールパーティ",
  "maker": "セガ",
  "releaseDate": "2003/7/17",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "409",
  "ini": "ほ",
  "name": "ボクらの太陽",
  "maker": "コナミ",
  "releaseDate": "2003/7/17",
  "price": "4980",
  "genre": "RPG",
  "icNo": "16"
 },
 {
  "id": "410",
  "ini": "さ",
  "name": "CYBERDRIVE ZOIDS 機獣の戦士ヒュウ",
  "maker": "トミー",
  "releaseDate": "2003/7/18",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "411",
  "ini": "し",
  "name": "ジュラシック・パーク インスティテュートツアー ダイナソーレスキュー",
  "maker": "ロケットカンパニー",
  "releaseDate": "2003/7/18",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "412",
  "ini": "と",
  "name": "ドラゴンドライブ ワールドDブレイク",
  "maker": "バンプレスト",
  "releaseDate": "2003/7/18",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "413",
  "ini": "は",
  "name": "ハムスター倶楽部4 しげっち大脱走",
  "maker": "ジョルダン",
  "releaseDate": "2003/7/18",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "414",
  "ini": "み",
  "name": "ミッキーとミニーのマジカルクエスト2",
  "maker": "カプコン",
  "releaseDate": "2003/7/18",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "415",
  "ini": "し",
  "name": "シャイニング・ソウルⅡ",
  "maker": "セガ",
  "releaseDate": "2003/7/24",
  "price": "5980",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "416",
  "ini": "お",
  "name": "鬼武者 Onimusha Tactics",
  "maker": "カプコン",
  "releaseDate": "2003/7/25",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "417",
  "ini": "と",
  "name": "トップギア・ラリーSP",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "2003/7/25",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "418",
  "ini": "わ",
  "name": "わんニャンどうぶつ病院 ☆動物のお医者さん育成ゲーム☆",
  "maker": "TDKコア",
  "releaseDate": "2003/7/25",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "419",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン パズルdeコール！",
  "maker": "アトラス",
  "releaseDate": "2003/7/25",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "420",
  "ini": "め",
  "name": "名探偵コナン 狙われた探偵",
  "maker": "バンプレスト",
  "releaseDate": "2003/7/25",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "421",
  "ini": "あ",
  "name": "アラジン",
  "maker": "カプコン",
  "releaseDate": "2003/8/1",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "422",
  "ini": "て",
  "name": "テイルズ オブ ファンタジア",
  "maker": "ナムコ",
  "releaseDate": "2003/8/1",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "423",
  "ini": "ほ",
  "name": "ポケモンピンボール ルビー&サファイア",
  "maker": "任天堂",
  "releaseDate": "2003/8/1",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "424",
  "ini": "け",
  "name": "幻想魔伝最遊記 反逆の闘神太子",
  "maker": "デジタルキッズ",
  "releaseDate": "2003/8/1",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "425",
  "ini": "こ",
  "name": "仔犬といっしょ 愛情物語",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/8/1",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "426",
  "ini": "て",
  "name": "デュエル・マスターズ",
  "maker": "タカラ",
  "releaseDate": "2003/8/7",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "427",
  "ini": "ほ",
  "name": "ボボボーボ・ボーボボ マジで!!？真拳勝負",
  "maker": "ハドソン",
  "releaseDate": "2003/8/7",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "428",
  "ini": "す",
  "name": "スーパーロボット対戦D",
  "maker": "バンプレスト",
  "releaseDate": "2003/8/8",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "429",
  "ini": "と",
  "name": "どうぶつ島のチョビぐるみ",
  "maker": "ロケットカンパニー",
  "releaseDate": "2003/8/8",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "430",
  "ini": "ろ",
  "name": "ロックマンエグゼ バトルチップGP",
  "maker": "カプコン",
  "releaseDate": "2003/8/8",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "431",
  "ini": "お",
  "name": "おしゃれプリンセス3",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/8/29",
  "price": "4800",
  "genre": "育成・シミュレーション・パズル",
  "icNo": "1"
 },
 {
  "id": "432",
  "ini": "し",
  "name": "新約 聖剣伝説",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2003/8/29",
  "price": "5800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "433",
  "ini": "け",
  "name": "GetBackers 奪還屋 邪眼封印！",
  "maker": "コナミ",
  "releaseDate": "2003/9/4",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "434",
  "ini": "お",
  "name": "おじゃる丸 月光町さんぽでおじゃる",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/9/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "435",
  "ini": "し",
  "name": "新きせかえ物語",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2003/9/5",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "436",
  "ini": "て",
  "name": "伝説のスタフィー2",
  "maker": "任天堂",
  "releaseDate": "2003/9/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "437",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！ 対戦！まほうだま",
  "maker": "コナミ",
  "releaseDate": "2003/9/11",
  "price": "5800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "438",
  "ini": "な",
  "name": "NARUTO - ナルト - 木ノ葉戦記",
  "maker": "トミー",
  "releaseDate": "2003/9/12",
  "price": "4800",
  "genre": "シミュレーション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "439",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 炎の書",
  "maker": "アトラス",
  "releaseDate": "2003/9/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "440",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 氷の書",
  "maker": "アトラス",
  "releaseDate": "2003/9/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "441",
  "ini": "た",
  "name": "探偵学園Q 名探偵はキミだ！",
  "maker": "コナミ",
  "releaseDate": "2003/9/18",
  "price": "4980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "442",
  "ini": "か",
  "name": "かわいいペット！ゲームギャラリー",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/9/26",
  "price": "3300",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "443",
  "ini": "は",
  "name": "バトル×バトル 巨大魚伝説",
  "maker": "スターフィッシュ",
  "releaseDate": "2003/9/26",
  "price": "4800",
  "genre": "釣り・アクション",
  "icNo": "1"
 },
 {
  "id": "444",
  "ini": "し",
  "name": "真・女神転生Ⅱ",
  "maker": "アトラス",
  "releaseDate": "2003/9/26",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "445",
  "ini": "せ",
  "name": "絶体絶命でんぢゃらすじーさん 史上最強の土下座",
  "maker": "キッズステーション",
  "releaseDate": "2003/9/26",
  "price": "4800",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "446",
  "ini": "ま",
  "name": "マーメイドメロディー ぴちぴちピッチ",
  "maker": "コナミ",
  "releaseDate": "2003/10/9",
  "price": "4980",
  "genre": "リズム・アクション",
  "icNo": "1"
 },
 {
  "id": "447",
  "ini": "ほ",
  "name": "ボンバーマンジェッターズ ゲームコレクション",
  "maker": "ハドソン",
  "releaseDate": "2003/10/16",
  "price": "4800",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "448",
  "ini": "ま",
  "name": "魔探偵ロキRAGNAROK 幻想のラビリンス",
  "maker": "Ｊ・ウイング",
  "releaseDate": "2003/10/16",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "449",
  "ini": "お",
  "name": "オリエンタルブルー -青の天外 -",
  "maker": "任天堂",
  "releaseDate": "2003/10/24",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "450",
  "ini": "れ",
  "name": "レジェンド オブ ダイナミック 豪翔伝 崩界の輪舞曲",
  "maker": "バンプレスト",
  "releaseDate": "2003/10/24",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "451",
  "ini": "み",
  "name": "みんなのソフトシリーズ ZOOO",
  "maker": "サクセス",
  "releaseDate": "2003/10/31",
  "price": "3480",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "452",
  "ini": "み",
  "name": "みんなのソフトシリーズ みんなの麻雀",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/10/31",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "453",
  "ini": "み",
  "name": "みんなのソフトシリーズ 上海",
  "maker": "サクセス",
  "releaseDate": "2003/10/31",
  "price": "3480",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "454",
  "ini": "さ",
  "name": "サンリオピューロランド オールキャラクターズ",
  "maker": "トミー",
  "releaseDate": "2003/11/1",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "455",
  "ini": "は",
  "name": "ハリー・ポッター クイディッチ・ワールドカップ",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2003/11/13",
  "price": "4800",
  "genre": "スポーツ・アクション",
  "icNo": "1"
 },
 {
  "id": "456",
  "ini": "こ",
  "name": "こいぬちゃんのはじめてのおさんぽ 子犬のこころ育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2003/11/14",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "457",
  "ini": "す",
  "name": "スライムもりもりドラゴンクエスト 衝撃のしっぽ団",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2003/11/14",
  "price": "5800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "458",
  "ini": "は",
  "name": "パズにん うみにんのパズルでにむ",
  "maker": "メトロ・スリーディジャパン",
  "releaseDate": "2003/11/21",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "459",
  "ini": "ま",
  "name": "マリオ&ルイージRPG",
  "maker": "任天堂",
  "releaseDate": "2003/11/21",
  "price": "4800",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "460",
  "ini": "み",
  "name": "ミッキーとドナルドのマジカルクエスト3",
  "maker": "カプコン",
  "releaseDate": "2003/11/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "461",
  "ini": "み",
  "name": "みんなのソフトシリーズ ひょっこりひょうたん島 ドン・ガバチョ大活躍の巻",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/11/21",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "462",
  "ini": "え",
  "name": "SDガンダム Gジェネレーション ADVANCE",
  "maker": "バンダイ",
  "releaseDate": "2003/11/27",
  "price": "5800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "463",
  "ini": "え",
  "name": "F-ZERO ファルコン伝説",
  "maker": "任天堂",
  "releaseDate": "2003/11/28",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "464",
  "ini": "は",
  "name": "ハムスター物語3EX.4.スペシャル",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/11/28",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "465",
  "ini": "み",
  "name": "みんなのソフトシリーズ テトリス アドバンス",
  "maker": "サクセス",
  "releaseDate": "2003/11/28",
  "price": "3480",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "466",
  "ini": "あ",
  "name": "あしたのジョー 真っ赤に燃え上がれ！",
  "maker": "コナミ",
  "releaseDate": "2003/12/4",
  "price": "4980",
  "genre": "スポーツ・アクション",
  "icNo": "1"
 },
 {
  "id": "467",
  "ini": "く",
  "name": "クラッシュ・バンディクー アドバンス2 ぐるぐるさいみん大パニック！？",
  "maker": "コナミ",
  "releaseDate": "2003/12/4",
  "price": "4980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "468",
  "ini": "そ",
  "name": "ソニックバトル",
  "maker": "セガ",
  "releaseDate": "2003/12/4",
  "price": "4980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "469",
  "ini": "は",
  "name": "パワプロクンポケット6",
  "maker": "コナミ",
  "releaseDate": "2003/12/4",
  "price": "4980",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "470",
  "ini": "ほ",
  "name": "冒険遊記プラスターワールド 伝説のプラストゲートEX",
  "maker": "タカラ",
  "releaseDate": "2003/12/4",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "471",
  "ini": "み",
  "name": "みんなの王子様",
  "maker": "コナミ",
  "releaseDate": "2003/12/4",
  "price": "4980",
  "genre": "パズル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "472",
  "ini": "か",
  "name": "学園戦記ムリョウ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/12/5",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "473",
  "ini": "か",
  "name": "ガチャステ！ダイナデバイス2 ドラゴン",
  "maker": "ロケットカンパニー",
  "releaseDate": "2003/12/5",
  "price": "4800",
  "genre": "バトル・アクション",
  "icNo": "1"
 },
 {
  "id": "474",
  "ini": "か",
  "name": "ガチャステ！ダイナデバイス2 フェニックス",
  "maker": "ロケットカンパニー",
  "releaseDate": "2003/12/5",
  "price": "4800",
  "genre": "バトル・アクション",
  "icNo": "1"
 },
 {
  "id": "475",
  "ini": "し",
  "name": "シナモロール ここにいるよ",
  "maker": "イマジニア",
  "releaseDate": "2003/12/5",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "476",
  "ini": "し",
  "name": "シルバニアファミリー 妖精のステッキとふしぎの木 マロンイヌの女の子",
  "maker": "エポック社",
  "releaseDate": "2003/12/5",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "477",
  "ini": "ふ",
  "name": "ファインディング・ニモ",
  "maker": "ユークス",
  "releaseDate": "2003/12/6",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "478",
  "ini": "け",
  "name": "ゲゲゲの鬼太郎 危機一髪！妖怪列島",
  "maker": "コナミ",
  "releaseDate": "2003/12/11",
  "price": "4980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "479",
  "ini": "こ",
  "name": "ゴジラ 怪獣大乱闘 アドバンス",
  "maker": "アタリジャパン",
  "releaseDate": "2003/12/11",
  "price": "4800",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "480",
  "ini": "こ",
  "name": "コロッケ！3 グラニュー王国の謎",
  "maker": "コナミ",
  "releaseDate": "2003/12/11",
  "price": "4980",
  "genre": "アドベンチャー・アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "481",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！8人の時の妖精",
  "maker": "コナミ",
  "releaseDate": "2003/12/11",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "482",
  "ini": "か",
  "name": "カードキャプターさくら さくらカードdeミニゲーム",
  "maker": "TDKコア",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "483",
  "ini": "こ",
  "name": "金色のガッシュベル!! うなれ！友情の電撃",
  "maker": "バンプレスト",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "484",
  "ini": "す",
  "name": "スーパードンキーコング",
  "maker": "任天堂",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "485",
  "ini": "ひ",
  "name": "ピカピカナース物語 はつらつナース育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "486",
  "ini": "ろ",
  "name": "ロックマンエグゼ4 トーナメント ブルームーン",
  "maker": "カプコン",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "8"
 },
 {
  "id": "487",
  "ini": "ろ",
  "name": "ロックマンエグゼ4 トーナメント レッドサン",
  "maker": "カプコン",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "7"
 },
 {
  "id": "488",
  "ini": "ほ",
  "name": "牧場物語 ミネラルタウンのなかまたち For ガール",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2003/12/12",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "489",
  "ini": "あ",
  "name": "ASTRO BOY 鉄腕アトム アトムハートの秘密",
  "maker": "セガ",
  "releaseDate": "2003/12/18",
  "price": "4980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "490",
  "ini": "し",
  "name": "SIMPLE2960ともだちシリーズ Vol.3 THE いつでもパズル まっすぐ揃えてストローズ",
  "maker": "D3パブリッシャー",
  "releaseDate": "2003/12/18",
  "price": "2960",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "491",
  "ini": "し",
  "name": "SIMPLE2960ともだちシリーズ Vol.4 THE トランプ みんなで遊べる12種類のトランプゲーム",
  "maker": "D3パブリッシャー",
  "releaseDate": "2003/12/18",
  "price": "2960",
  "genre": "テーブル・ゲーム集",
  "icNo": "1"
 },
 {
  "id": "492",
  "ini": "ま",
  "name": "マーメイドメロディー ぴちぴちピッチ ぴちぴちパーティー",
  "maker": "コナミ",
  "releaseDate": "2003/12/18",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "493",
  "ini": "め",
  "name": "メダル オブ オナー アドバンス",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2003/12/18",
  "price": "4800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "494",
  "ini": "お",
  "name": "お茶犬の部屋",
  "maker": "エム・ティー･オー",
  "releaseDate": "2003/12/19",
  "price": "3980",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "495",
  "ini": "わ",
  "name": "わんわん名探偵",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/12/19",
  "price": "4800",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "496",
  "ini": "ま",
  "name": "魔女っ子クリームちゃんのごっこシリーズ① わんにゃんアイドル学園",
  "maker": "カルチャーブレーン",
  "releaseDate": "2003/12/30",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "497",
  "ini": "ろ",
  "name": "ロード・オブ・ザ・リング 王の帰還",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/1/8",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "498",
  "ini": "さ",
  "name": "ザ・シムズ",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/1/22",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "499",
  "ini": "き",
  "name": "逆転裁判3",
  "maker": "カプコン",
  "releaseDate": "2004/1/23",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "500",
  "ini": "ほ",
  "name": "ポケットモンスター ファイアレッド",
  "maker": "任天堂",
  "releaseDate": "2004/1/29",
  "price": "4800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "501",
  "ini": "ほ",
  "name": "ポケットモンスター リーフグリーン",
  "maker": "任天堂",
  "releaseDate": "2004/1/29",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "502",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ エキスパート3",
  "maker": "コナミ",
  "releaseDate": "2004/2/5",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "503",
  "ini": "せ",
  "name": "007 エブリシング オア ナッシング",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/2/11",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "504",
  "ini": "り",
  "name": "リリパット王国 リリモニといっしょプニ！",
  "maker": "セガ",
  "releaseDate": "2004/2/12",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "505",
  "ini": "ふ",
  "name": "ファミコンミニ01 スーパーマリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "506",
  "ini": "ふ",
  "name": "ファミコンミニ02 ドンキーコング",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "507",
  "ini": "ふ",
  "name": "ファミコンミニ03 アイスクライマー",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "508",
  "ini": "ふ",
  "name": "ファミコンミニ04 エキサイトバイク",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "レース",
  "icNo": "11"
 },
 {
  "id": "509",
  "ini": "ふ",
  "name": "ファミコンミニ05 ゼルダの伝説1",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション・アドベンチャー",
  "icNo": "11"
 },
 {
  "id": "510",
  "ini": "ふ",
  "name": "ファミコンミニ06 パックマン",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "511",
  "ini": "ふ",
  "name": "ファミコンミニ07 ゼビウス",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "シューティング",
  "icNo": "11"
 },
 {
  "id": "512",
  "ini": "ふ",
  "name": "ファミコンミニ08 マッピー",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "513",
  "ini": "ふ",
  "name": "ファミコンミニ09 ボンバーマン",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "514",
  "ini": "ふ",
  "name": "ファミコンミニ10 スターソルジャー",
  "maker": "任天堂",
  "releaseDate": "2004/2/14",
  "price": "2000",
  "genre": "シューティング",
  "icNo": "11"
 },
 {
  "id": "515",
  "ini": "た",
  "name": "探偵学園Q 究極トリックに挑め！",
  "maker": "コナミ",
  "releaseDate": "2004/3/4",
  "price": "4980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "516",
  "ini": "お",
  "name": "おしゃれワンコ",
  "maker": "エム・ティー･オー",
  "releaseDate": "2004/3/5",
  "price": "5040",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "517",
  "ini": "た",
  "name": "ダウンタウン熱血物語ex",
  "maker": "アトラス",
  "releaseDate": "2004/3/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "518",
  "ini": "た",
  "name": "双載龍（ダブルドラゴン） アドバンス",
  "maker": "アトラス",
  "releaseDate": "2004/3/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "519",
  "ini": "た",
  "name": "対決！ウルトラヒーロー",
  "maker": "ジョルダン",
  "releaseDate": "2004/3/5",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "520",
  "ini": "わ",
  "name": "ONE PIECE ゴーイングベースボール",
  "maker": "バンダイ",
  "releaseDate": "2004/3/11",
  "price": "5040",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "521",
  "ini": "て",
  "name": "テニスの王子様2004 GLORIOUS GOLD",
  "maker": "コナミ",
  "releaseDate": "2004/3/18",
  "price": "4980",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "522",
  "ini": "て",
  "name": "テニスの王子様2004 STYLISH SILVER",
  "maker": "コナミ",
  "releaseDate": "2004/3/18",
  "price": "4980",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "523",
  "ini": "て",
  "name": "デュエル・マスターズ2 インビンシブル・アドバンス",
  "maker": "タカラ",
  "releaseDate": "2004/3/18",
  "price": "4800",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "524",
  "ini": "ま",
  "name": "マーメイドメロディー ぴちぴちピッチ ぴちぴちっとライブスタート！",
  "maker": "コナミ",
  "releaseDate": "2004/3/18",
  "price": "4980",
  "genre": "リズム・アクション",
  "icNo": "1"
 },
 {
  "id": "525",
  "ini": "せ",
  "name": "絶体絶命でんぢゃらすじｰさん 泣きの1回 絶対服従ばいおれんす校長 ワガハイが一番えらいんじゃい!!",
  "maker": "キッズステーション",
  "releaseDate": "2004/3/18",
  "price": "4800",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "ゆ",
  "name": "遊☆戯☆王 双六のスゴロク",
  "maker": "コナミ",
  "releaseDate": "2004/3/18",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "527",
  "ini": "ほ",
  "name": "ボボボーボ・ボーボボ 9極戦士ギャグ融合",
  "maker": "ハドソン",
  "releaseDate": "2004/3/25",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "528",
  "ini": "ま",
  "name": "魔女っ子クリームちゃんのごっこシリーズ② きせかえエンジェル",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/3/25",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "529",
  "ini": "あ",
  "name": "あかちゃんどうぶつ園 動物のあかちゃん飼育係育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2004/3/26",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "530",
  "ini": "と",
  "name": "ドラゴンボールZ 舞空闘劇",
  "maker": "バンプレスト",
  "releaseDate": "2004/3/26",
  "price": "4800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "531",
  "ini": "ふ",
  "name": "ぷくぷく天然かいらんばん 恋のキューピット大作戦",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2004/3/26",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "532",
  "ini": "り",
  "name": "リカちゃんのおしゃれ日記",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2004/3/26",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "533",
  "ini": "は",
  "name": "鋼の錬金術師 迷走の輪舞曲",
  "maker": "バンダイ",
  "releaseDate": "2004/3/26",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "534",
  "ini": "て",
  "name": "デジモンレーシング",
  "maker": "バンダイ",
  "releaseDate": "2004/4/1",
  "price": "5040",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "535",
  "ini": "と",
  "name": "東京魔人學園 符咒封録",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2004/4/1",
  "price": "6090",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "536",
  "ini": "こ",
  "name": "仔犬といっしょ2 あ・い・じ・ょ・う ものがたり",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/4/9",
  "price": "5040",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "537",
  "ini": "ほ",
  "name": "星のカービィ 鏡の大迷宮",
  "maker": "任天堂",
  "releaseDate": "2004/4/15",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "538",
  "ini": "く",
  "name": "クレヨンしんちゃん 嵐を呼ぶ シネマランドの大冒険！",
  "maker": "バンプレスト",
  "releaseDate": "2004/4/16",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "539",
  "ini": "し",
  "name": "シレン・モンスターズ NETSAL",
  "maker": "チュンソフト",
  "releaseDate": "2004/4/22",
  "price": "5040",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "540",
  "ini": "ま",
  "name": "マリオゴルフ GBAツアー",
  "maker": "任天堂",
  "releaseDate": "2004/4/22",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "541",
  "ini": "ひ",
  "name": "Ｐｉａ キャロットへようこそ!!3.3",
  "maker": "NECインターチャネル",
  "releaseDate": "2004/4/23",
  "price": "5900",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "542",
  "ini": "か",
  "name": "カードキャプターさくら さくらカード編 さくらとカードとおともだち",
  "maker": "エム・ティー･オー",
  "releaseDate": "2004/4/23",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "543",
  "ini": "ろ",
  "name": "ロックマンZERO3",
  "maker": "カプコン",
  "releaseDate": "2004/4/23",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "544",
  "ini": "う",
  "name": "宇宙のステルヴィア",
  "maker": "キングレコード",
  "releaseDate": "2004/4/23",
  "price": "6090",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "545",
  "ini": "な",
  "name": "NARUTO -ナルト- 最強忍者 大結集2",
  "maker": "トミー",
  "releaseDate": "2004/4/29",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "546",
  "ini": "え",
  "name": "SFアドベンチャー ZERO ONE SP",
  "maker": "フウキ",
  "releaseDate": "2004/4/29",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "547",
  "ini": "ひ",
  "name": "ピューと吹く！ジャガー ビョ～と出る！メガネくん",
  "maker": "コナミ",
  "releaseDate": "2004/4/29",
  "price": "5229",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "548",
  "ini": "み",
  "name": "ミッキーのポケットリゾート",
  "maker": "トミー",
  "releaseDate": "2004/4/29",
  "price": "5040",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "549",
  "ini": "こ",
  "name": "鋼鉄帝国 from HOT・B",
  "maker": "スターフィッシュ",
  "releaseDate": "2004/4/30",
  "price": "5040",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "550",
  "ini": "き",
  "name": "機動戦士ガンダムSEED 友と君と戦場で。",
  "maker": "バンダイ",
  "releaseDate": "2004/5/13",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "551",
  "ini": "ふ",
  "name": "ファミコンミニ11 マリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "552",
  "ini": "ふ",
  "name": "ファミコンミニ12 クルクルランド",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "553",
  "ini": "ふ",
  "name": "ファミコンミニ13 バルーンファイト",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "554",
  "ini": "ふ",
  "name": "ファミコンミニ14 レッキングクルー",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "555",
  "ini": "ふ",
  "name": "ファミコンミニ15 ドクターマリオ",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "パズル",
  "icNo": "11"
 },
 {
  "id": "556",
  "ini": "ふ",
  "name": "ファミコンミニ16 ディグダグ",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "557",
  "ini": "ふ",
  "name": "ファミコンミニ17 高橋名人の冒険島",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "558",
  "ini": "ふ",
  "name": "ファミコンミニ18 魔界村",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "559",
  "ini": "ふ",
  "name": "ファミコンミニ19 ツインビー",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "シューティング",
  "icNo": "11"
 },
 {
  "id": "560",
  "ini": "ふ",
  "name": "ファミコンミニ20 がんばれゴエモン！からくり道中",
  "maker": "任天堂",
  "releaseDate": "2004/5/21",
  "price": "2000",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "561",
  "ini": "し",
  "name": "じゃじゃ丸Jr.伝承記 ジャレコレもあり候",
  "maker": "ジャレコ",
  "releaseDate": "2004/5/27",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "562",
  "ini": "め",
  "name": "メトロイド ゼロミッション",
  "maker": "任天堂",
  "releaseDate": "2004/5/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "563",
  "ini": "ま",
  "name": "マリオvs.ドンキーコング",
  "maker": "任天堂",
  "releaseDate": "2004/6/10",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "564",
  "ini": "そ",
  "name": "ソニックアドバンス3",
  "maker": "セガ",
  "releaseDate": "2004/6/17",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "565",
  "ini": "ふ",
  "name": "フロッガー 古代文明のなぞ",
  "maker": "コナミ",
  "releaseDate": "2004/6/17",
  "price": "5229",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "566",
  "ini": "ひ",
  "name": "BBボール",
  "maker": "ミコット・エンド・バサラ",
  "releaseDate": "2004/6/24",
  "price": "6090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "567",
  "ini": "す",
  "name": "スーパーチャイニーズ1・2 アドバンス",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/6/24",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "568",
  "ini": "と",
  "name": "ドラゴンクエスト・キャラクターズ トルネコの大冒険3アドバンス 不思議のダンジョン",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2004/6/24",
  "price": "5980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "569",
  "ini": "わ",
  "name": "ワンコでくるりん！ワンクル",
  "maker": "エム・ティー･オー",
  "releaseDate": "2004/6/25",
  "price": "5040",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "570",
  "ini": "は",
  "name": "ハリー・ポッターとアズカバンの囚人",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/6/26",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "571",
  "ini": "す",
  "name": "スーパードンキーコング2",
  "maker": "任天堂",
  "releaseDate": "2004/7/1",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "572",
  "ini": "か",
  "name": "かいけつゾロリとまほうのゆうえんち お姫様をすくえ！",
  "maker": "バンダイ",
  "releaseDate": "2004/7/15",
  "price": "5040",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "573",
  "ini": "て",
  "name": "でじこミュニケーション2（にょ） 打倒！ブラックゲマゲマ団",
  "maker": "ブロッコリー",
  "releaseDate": "2004/7/15",
  "price": "5800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "574",
  "ini": "と",
  "name": "とっとこハム太郎 ハムハムスポーツ",
  "maker": "任天堂",
  "releaseDate": "2004/7/15",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "575",
  "ini": "も",
  "name": "モンスターサマナー",
  "maker": "アーティン",
  "releaseDate": "2004/7/15",
  "price": "5000",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "576",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！夢のカケラ",
  "maker": "コナミ",
  "releaseDate": "2004/7/15",
  "price": "5229",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "577",
  "ini": "こ",
  "name": "金色のガッシュベル!! 魔界のブックマーク",
  "maker": "バンプレスト",
  "releaseDate": "2004/7/16",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "578",
  "ini": "せ",
  "name": "絶体絶命でんぢゃらすじーさん痛 怒りのおしおきブルース",
  "maker": "キッズステーション",
  "releaseDate": "2004/7/16",
  "price": "5040",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "579",
  "ini": "け",
  "name": "Get Ride！アムドライバー 閃光のヒーロー誕生！",
  "maker": "コナミ",
  "releaseDate": "2004/7/22",
  "price": "5229",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "580",
  "ini": "な",
  "name": "NARUTO -ナルト- ナルトRPG 受けつがれし火の意志",
  "maker": "トミー",
  "releaseDate": "2004/7/22",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "581",
  "ini": "こ",
  "name": "コロッケ！4 バンクの森の守護神",
  "maker": "コナミ",
  "releaseDate": "2004/7/22",
  "price": "5229",
  "genre": "アドベンチャー・アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "582",
  "ini": "て",
  "name": "デュエルマスターズ2 切札勝舞Ver.",
  "maker": "タカラ",
  "releaseDate": "2004/7/22",
  "price": "5040",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "583",
  "ini": "か",
  "name": "学校の怪談 百妖箱の封印",
  "maker": "TDKコア",
  "releaseDate": "2004/7/22",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "584",
  "ini": "は",
  "name": "鋼の錬金術師 想い出の奏鳴曲",
  "maker": "バンダイ",
  "releaseDate": "2004/7/22",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "585",
  "ini": "そ",
  "name": "続・ボクらの太陽 太陽少年ジャンゴ",
  "maker": "コナミ",
  "releaseDate": "2004/7/22",
  "price": "5229",
  "genre": "アクション・RPG",
  "icNo": "16"
 },
 {
  "id": "586",
  "ini": "と",
  "name": "ドラゴンボールＺ THE LEGACY OF GOKUⅡ INTERNATIONAL",
  "maker": "バンプレスト",
  "releaseDate": "2004/7/23",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "587",
  "ini": "ふ",
  "name": "ぷよぷよフィーバー",
  "maker": "セガ",
  "releaseDate": "2004/7/24",
  "price": "5040",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "588",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅠ・Ⅱ アドバンス",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2004/7/29",
  "price": "6090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "589",
  "ini": "と",
  "name": "ドンちゃんパズル 花火でドーン！アドバンス",
  "maker": "アルゼ",
  "releaseDate": "2004/7/29",
  "price": "5040",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "590",
  "ini": "は",
  "name": "パワプロクンポケット1・2",
  "maker": "コナミ",
  "releaseDate": "2004/7/29",
  "price": "4179",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "591",
  "ini": "れ",
  "name": "レジェンズ 甦る試練の島",
  "maker": "バンダイ",
  "releaseDate": "2004/7/29",
  "price": "6279",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "592",
  "ini": "は",
  "name": "花火百景アドバンス",
  "maker": "アルゼ",
  "releaseDate": "2004/7/29",
  "price": "5040",
  "genre": "パチスロ",
  "icNo": "1"
 },
 {
  "id": "593",
  "ini": "ひ",
  "name": "B-伝説！ バトルビーダマン 燃えろ！ビー魂!!",
  "maker": "タカラ",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "アドベンチャー・アクション",
  "icNo": "1"
 },
 {
  "id": "594",
  "ini": "え",
  "name": "SDガンダムフォース",
  "maker": "バンダイ",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "595",
  "ini": "う",
  "name": "ウルトラ警備隊 モンスターアタック",
  "maker": "ロケットカンパニー",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "596",
  "ini": "し",
  "name": "シャニング・フォース 黒き竜の復活",
  "maker": "セガ",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "597",
  "ini": "と",
  "name": "どうぶつ島のチョビぐるみ2 タマちゃん物語",
  "maker": "ロケットカンパニー",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "598",
  "ini": "ひ",
  "name": "ひまわりどうぶつ病院 ペットのお医者さん育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2004/8/5",
  "price": "5040",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "599",
  "ini": "て",
  "name": "伝説のスタフィー3",
  "maker": "任天堂",
  "releaseDate": "2004/8/5",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "600",
  "ini": "ろ",
  "name": "ロックマンエグゼ4.5 リアルオペレーション",
  "maker": "カプコン",
  "releaseDate": "2004/8/6",
  "price": "5040",
  "genre": "データアクションRPG",
  "icNo": "1"
 },
 {
  "id": "601",
  "ini": "ふ",
  "name": "ファミコンミニ21 スーパーマリオブラザーズ2",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "602",
  "ini": "ふ",
  "name": "ファミコンミニ22 謎の村雨城",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "603",
  "ini": "ふ",
  "name": "ファミコンミニ23 メトロイド",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "604",
  "ini": "ふ",
  "name": "ファミコンミニ24 光神話 パルテナの鏡",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "605",
  "ini": "ふ",
  "name": "ファミコンミニ25 リンクの冒険",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション・RPG",
  "icNo": "12"
 },
 {
  "id": "606",
  "ini": "ふ",
  "name": "ファミコンミニ26 ふぁみこんむかし話 新・鬼ヶ島 前後編",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アドベンチャー",
  "icNo": "12"
 },
 {
  "id": "607",
  "ini": "ふ",
  "name": "ファミコンミニ27 ファミコン探偵倶楽部 消えた後継者 前後編",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アドベンチャー",
  "icNo": "12"
 },
 {
  "id": "608",
  "ini": "ふ",
  "name": "ファミコンミニ28 ファミコン探偵倶楽部PART2 うしろに立つ少女 前後編",
  "maker": "任天堂",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アドベンチャー",
  "icNo": "12"
 },
 {
  "id": "609",
  "ini": "ふ",
  "name": "ファミコンミニ29 悪魔城ドラキュラ",
  "maker": "コナミ",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "610",
  "ini": "ふ",
  "name": "ファミコンミニ30 SDガンダムワールド ガチャポン戦士 スクランブルウォーズ",
  "maker": "バンダイ",
  "releaseDate": "2004/8/10",
  "price": "2000",
  "genre": "シミュレーション",
  "icNo": "12"
 },
 {
  "id": "611",
  "ini": "ふ",
  "name": " 2つあそべるツインシリーズ① めざせデビュー！ ファッションデザイナーものがたり+かわいいペットゲームギャラリー2",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/8/12",
  "price": "5040",
  "genre": "その他・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "612",
  "ini": "さ",
  "name": "サモンナイト クラフトソード物語2",
  "maker": "バンプレスト",
  "releaseDate": "2004/8/20",
  "price": "6090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "613",
  "ini": "く",
  "name": "クラッシュ・バンディクー 爆走！ニトロカート",
  "maker": "コナミ",
  "releaseDate": "2004/8/26",
  "price": "5040",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "614",
  "ini": "す",
  "name": "スーパーマリオボール",
  "maker": "任天堂",
  "releaseDate": "2004/8/26",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "615",
  "ini": "ほ",
  "name": "ボボボーボ・ボーボボ 爆闘ハジケ大戦",
  "maker": "ハドソン",
  "releaseDate": "2004/9/9",
  "price": "5040",
  "genre": "対戦格闘・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "616",
  "ini": "ほ",
  "name": "ポケットモンスター エメラルド",
  "maker": "任天堂",
  "releaseDate": "2004/9/16",
  "price": "3800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "617",
  "ini": "た",
  "name": "DAN DOH!! 飛ばせ！勝利のスマイルショット!!",
  "maker": "タカラ",
  "releaseDate": "2004/9/22",
  "price": "5040",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "618",
  "ini": "あ",
  "name": "アドバンス ガーディアンヒーローズ",
  "maker": "トレジャー",
  "releaseDate": "2004/9/22",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "619",
  "ini": "え",
  "name": "エンジェルコレクション2 ピチモになろう",
  "maker": "エム・ティー･オー",
  "releaseDate": "2004/9/22",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "620",
  "ini": "み",
  "name": "みんなのソフトシリーズ みんなの将棋",
  "maker": "サクセス",
  "releaseDate": "2004/9/24",
  "price": "3480",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "621",
  "ini": "ふ",
  "name": "ファイアーエムブレム 聖魔の光石",
  "maker": "任天堂",
  "releaseDate": "2004/10/7",
  "price": "4800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "622",
  "ini": "ま",
  "name": "まわるメイド イン ワリオ",
  "maker": "任天堂",
  "releaseDate": "2004/10/14",
  "price": "4800",
  "genre": "ミニゲーム",
  "icNo": "15"
 },
 {
  "id": "623",
  "ini": "え",
  "name": "F-ZERO CLIMAX",
  "maker": "任天堂",
  "releaseDate": "2004/10/21",
  "price": "4800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "624",
  "ini": "せ",
  "name": "戦闘員 山田はじめ",
  "maker": "キッズステーション",
  "releaseDate": "2004/10/21",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "625",
  "ini": "ふ",
  "name": "2つあそべるツインシリーズ② おしゃれプリンセス4+スウィートライフ+恋愛占い大作戦",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/10/22",
  "price": "4599",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "626",
  "ini": "お",
  "name": "お茶犬くるりん ほんわかパズルで「ほっ」としよ？",
  "maker": "エム・ティー・オー",
  "releaseDate": "2004/10/28",
  "price": "4179",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "627",
  "ini": "き",
  "name": "球闘士バトローラーX",
  "maker": "バンダイ",
  "releaseDate": "2004/10/28",
  "price": "5040",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "628",
  "ini": "ふ",
  "name": "フルーツ村のどうぶつたち",
  "maker": "TDKコア",
  "releaseDate": "2004/10/28",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "629",
  "ini": "も",
  "name": "燃えろ!!ジャレココレクション",
  "maker": "ジャレコ",
  "releaseDate": "2004/10/28",
  "price": "5040",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "630",
  "ini": "し",
  "name": "□いアタマを○くする。アドバンス 漢字・計算",
  "maker": "IEインスティテュート",
  "releaseDate": "2004/11/4",
  "price": "5040",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "631",
  "ini": "し",
  "name": "□いアタマを○くする。アドバンス 国語・算数・社会・理科",
  "maker": "IEインスティテュート",
  "releaseDate": "2004/11/4",
  "price": "5040",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "632",
  "ini": "す",
  "name": "スーパーリアル麻雀 同窓会",
  "maker": "ロケットカンパニー",
  "releaseDate": "2004/11/4",
  "price": "5040",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "633",
  "ini": "せ",
  "name": "ゼルダの伝説 ふしぎのぼうし",
  "maker": "任天堂",
  "releaseDate": "2004/11/4",
  "price": "4800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "634",
  "ini": "し",
  "name": "真・女神転生デビルチルドレン メシアライザー",
  "maker": "ロケットカンパニー",
  "releaseDate": "2004/11/4",
  "price": "5040",
  "genre": "RTS",
  "icNo": "1"
 },
 {
  "id": "635",
  "ini": "き",
  "name": "キングダムハーツ チェイン オブ メモリーズ",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2004/11/11",
  "price": "6279",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "636",
  "ini": "と",
  "name": "ドラゴンボール アドバンスアドベンチャー",
  "maker": "バンプレスト",
  "releaseDate": "2004/11/18",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "637",
  "ini": "め",
  "name": "メタルスラッグ アドバンス",
  "maker": "SNKプレイモア",
  "releaseDate": "2004/11/18",
  "price": "5040",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "638",
  "ini": "か",
  "name": "学園アリス  ドキドキ★不思議たいけん",
  "maker": "キッズステーション",
  "releaseDate": "2004/11/18",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "639",
  "ini": "ふ",
  "name": "ファインディング・ニモ 新たなる冒険",
  "maker": "ユークス",
  "releaseDate": "2004/11/19",
  "price": "5040",
  "genre": "アクション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "640",
  "ini": "り",
  "name": "Riviera 約束の地リヴィエラ",
  "maker": "スティング",
  "releaseDate": "2004/11/25",
  "price": "6090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "641",
  "ini": "か",
  "name": "かわいい仔犬ワンダフル",
  "maker": "エム・ティー・オー",
  "releaseDate": "2004/11/25",
  "price": "5040",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "642",
  "ini": "き",
  "name": "機動戦士ガンダムSEED DESTINY",
  "maker": "バンダイ",
  "releaseDate": "2004/11/25",
  "price": "5040",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "643",
  "ini": "け",
  "name": "ゲームボーイウォーズアドバンス 1+2",
  "maker": "任天堂",
  "releaseDate": "2004/11/25",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "644",
  "ini": "ほ",
  "name": "忘却の旋律",
  "maker": "バンダイ",
  "releaseDate": "2004/11/25",
  "price": "6090",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "645",
  "ini": "あ",
  "name": "アレックボードンアドベンチャー タワー&シャフト アドバンス",
  "maker": "アルゼ",
  "releaseDate": "2004/11/27",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "646",
  "ini": "き",
  "name": "Kiss×Kiss 星鈴学園",
  "maker": "バンダイ",
  "releaseDate": "2004/12/2",
  "price": "5040",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "647",
  "ini": "み",
  "name": "Mr.インクレディブル",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2004/12/2",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "648",
  "ini": "さ",
  "name": "ザ・アーブズ シムズ・イン・ザ・シティ",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/12/2",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "649",
  "ini": "し",
  "name": "シナモン ゆめの大冒険",
  "maker": "イマジニア",
  "releaseDate": "2004/12/2",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "650",
  "ini": "し",
  "name": "シルバニアファミリー ファッションデザイナーになりたい！ くるみリスの女の子",
  "maker": "エポック社",
  "releaseDate": "2004/12/2",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "651",
  "ini": "は",
  "name": "パワプロクンポケット7",
  "maker": "コナミ",
  "releaseDate": "2004/12/2",
  "price": "5229",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "652",
  "ini": "く",
  "name": "クラッシュ・バンディクー アドバンス わくわく友ダチ大作戦！",
  "maker": "ビベンディユニバーサルゲームズ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "653",
  "ini": "け",
  "name": "ケロロ軍曹 対決！激走ケロンプリ大作戦であります!!",
  "maker": "サンライズインタラクティブ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "654",
  "ini": "こ",
  "name": "コロッケ！Great 時空の冒険者",
  "maker": "コナミ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "シミュレーション・アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "655",
  "ini": "す",
  "name": "スパイロ アドバンス わくわく友ダチ大作戦！",
  "maker": "ビベンディユニバーサルゲームズ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "656",
  "ini": "は",
  "name": "ハロー！アイドルデビュー キッズアイドル育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "育成シミュレーション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "657",
  "ini": "ふ",
  "name": "ぷくぷく天然かいらんばん ようこそ！イリュージョンランドへ",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "658",
  "ini": "ふ",
  "name": "ふたりはプリキュア ありえな～い！夢の国は大迷宮",
  "maker": "バンダイ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "659",
  "ini": "ほ",
  "name": "ポケいぬ",
  "maker": "アガツマ・エンタテインメント",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "660",
  "ini": "よ",
  "name": "ヨッシーの万有引力",
  "maker": "任天堂",
  "releaseDate": "2004/12/9",
  "price": "4800",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "661",
  "ini": "ろ",
  "name": "ロックマンエグゼ5 チーム オブ ブルース",
  "maker": "カプコン",
  "releaseDate": "2004/12/9",
  "price": "4990",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "662",
  "ini": "お",
  "name": "陰陽大戦記 零式",
  "maker": "バンダイ",
  "releaseDate": "2004/12/9",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "663",
  "ini": "ふ",
  "name": "2つあそべるツインシリーズ③ 昆虫モンスター 王者決定戦+スーパーチャイニーズラビリンス",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/10",
  "price": "5040",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "664",
  "ini": "ふ",
  "name": "2つあそべるツインシリーズ④ ハムハムモンスターEX ハムスター物語RPG+魔法の迷宮1.2.3",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/10",
  "price": "4599",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "665",
  "ini": "ふ",
  "name": "2つあそべるツインシリーズ⑤ わんわん名探偵EX+魔法の国のケーキ屋さん物語",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/10",
  "price": "4599",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "666",
  "ini": "ふ",
  "name": "2つあそべるツインシリーズ⑥ わんにゃんアイドル学園+仔犬といっしょスペシャル",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/10",
  "price": "4599",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "667",
  "ini": "け",
  "name": "GetRide！アムドライバー 出撃！バトルパーティー",
  "maker": "コナミ",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "テーブル・カードバトル",
  "icNo": "1"
 },
 {
  "id": "668",
  "ini": "そ",
  "name": "ゾイドサーガ フューザーズ",
  "maker": "トミー",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "669",
  "ini": "て",
  "name": "デュエル・マスターズ3",
  "maker": "アトラス",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "670",
  "ini": "り",
  "name": "リトルパティシエ ケーキのお城",
  "maker": "エム・ティー・オー",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "671",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！謎のカギと真実のトビラ",
  "maker": "コナミ",
  "releaseDate": "2004/12/16",
  "price": "4950",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "672",
  "ini": "わ",
  "name": "わんこ・みっくす ちわんこワールド",
  "maker": "TDKコア",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "673",
  "ini": "し",
  "name": "真型メダロット カブトバージョン",
  "maker": "イマジニア",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "674",
  "ini": "し",
  "name": "真型メダロット クワガタバージョン",
  "maker": "イマジニア",
  "releaseDate": "2004/12/16",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "675",
  "ini": "せ",
  "name": "絶体絶命でんぢゃらすじーさん3 果てしなき魔物語",
  "maker": "キッズステーション",
  "releaseDate": "2004/12/16",
  "price": "4980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "676",
  "ini": "か",
  "name": "かわいいペットゲームギャラリー2",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/17",
  "price": "3654",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "677",
  "ini": "ふ",
  "name": "2つあそべるうれしいシリーズ⑦ ツインパズル きせかえわんこEX+ニャーとチューのパズル レインボーマジック2",
  "maker": "カルチャーブレーン",
  "releaseDate": "2004/12/17",
  "price": "3549",
  "genre": "ゲーム集",
  "icNo": "1"
 },
 {
  "id": "678",
  "ini": "ろ",
  "name": "ロード・オブ・ザ・リング 中つ国第三紀",
  "maker": "エレクトロニック・アーツ",
  "releaseDate": "2004/12/22",
  "price": "5040",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "679",
  "ini": "こ",
  "name": "金色のガッシュベル!! うなれ！友情の電撃2",
  "maker": "バンプレスト",
  "releaseDate": "2004/12/22",
  "price": "5040",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "680",
  "ini": "け",
  "name": "幻星神ジャスティライザー　装着！地球の戦士たち",
  "maker": "コナミ",
  "releaseDate": "2004/12/23",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "681",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ インターナショナル2",
  "maker": "コナミ",
  "releaseDate": "2004/12/30",
  "price": "5040",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "682",
  "ini": "て",
  "name": "テイルズ オブ ザ ワールド なりきりダンジョン3",
  "maker": "ナムコ",
  "releaseDate": "2005/1/6",
  "price": "5040",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "683",
  "ini": "ま",
  "name": "マリオパーティ アドバンス",
  "maker": "任天堂",
  "releaseDate": "2005/1/13",
  "price": "4800",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "684",
  "ini": "み",
  "name": "みんなのソフトシリーズ ハッピートランプ20",
  "maker": "サクセス",
  "releaseDate": "2005/1/14",
  "price": "3129",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "685",
  "ini": "ほ",
  "name": "ホビットの冒険 ロード オブ ザ リング はじまりの物語",
  "maker": "コナミ",
  "releaseDate": "2005/1/20",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "686",
  "ini": "ほ",
  "name": "冒険王ビィト バスターズロード",
  "maker": "バンダイ",
  "releaseDate": "2005/1/20",
  "price": "5040",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "687",
  "ini": "か",
  "name": "がんばれ！ドッジファイターズ",
  "maker": "バンダイ",
  "releaseDate": "2005/1/27",
  "price": "5040",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "688",
  "ini": "さ",
  "name": "三國志英傑伝",
  "maker": "コーエー",
  "releaseDate": "2005/1/27",
  "price": "5040",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "689",
  "ini": "さ",
  "name": "三國志孔明伝",
  "maker": "コーエー",
  "releaseDate": "2005/1/27",
  "price": "5040",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "690",
  "ini": "た",
  "name": "探偵 神宮寺三郎 白い影の少女",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2005/1/27",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "691",
  "ini": "す",
  "name": "スーパーロボット大戦 ORIGINAL GENERATION2",
  "maker": "バンプレスト",
  "releaseDate": "2005/2/3",
  "price": "6090",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "692",
  "ini": "れ",
  "name": "レジェンズ サイン オブ ネクロム",
  "maker": "バンダイ",
  "releaseDate": "2005/2/17",
  "price": "5229",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "693",
  "ini": "ろ",
  "name": "ロックマンエグゼ5 チーム オブ カーネル",
  "maker": "カプコン",
  "releaseDate": "2005/2/24",
  "price": "4990",
  "genre": "RPG",
  "icNo": "9"
 },
 {
  "id": "694",
  "ini": "め",
  "name": "めざせ！甲子園",
  "maker": "tasuke",
  "releaseDate": "2005/3/10",
  "price": "5040",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "695",
  "ini": "せ",
  "name": "千年家族",
  "maker": "任天堂",
  "releaseDate": "2005/3/10",
  "price": "4800",
  "genre": "観察・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "696",
  "ini": "か",
  "name": "カッパの飼い方 How to breed kappas かぁたん大冒険！",
  "maker": "コナミ",
  "releaseDate": "2005/3/17",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "697",
  "ini": "に",
  "name": "にゃんにゃんにゃんこのにゃんコレクション",
  "maker": "エム・ティー・オー",
  "releaseDate": "2005/3/24",
  "price": "4179",
  "genre": "テーブル・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "698",
  "ini": "こ",
  "name": "昆虫モンスター バトルスタジアム",
  "maker": "カルチャーブレーン",
  "releaseDate": "2005/5/3",
  "price": "5040",
  "genre": "バトル・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "699",
  "ini": "こ",
  "name": "昆虫モンスター バトルマスター",
  "maker": "カルチャーブレーン",
  "releaseDate": "2005/5/3",
  "price": "5040",
  "genre": "バトル・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "700",
  "ini": "し",
  "name": "真・三國無双アドバンス",
  "maker": "コーエー",
  "releaseDate": "2005/3/24",
  "price": "5040",
  "genre": "アクション・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "701",
  "ini": "し",
  "name": "シャーク・テイル",
  "maker": "タイトー",
  "releaseDate": "2005/3/31",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "702",
  "ini": "ろ",
  "name": "ロックマンゼロ4",
  "maker": "カプコン",
  "releaseDate": "2005/4/21",
  "price": "4990",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "703",
  "ini": "け",
  "name": "傑作選！がんばれゴエモン1・2 ゆき姫とマッギネス",
  "maker": "コナミ",
  "releaseDate": "2005/4/21",
  "price": "3990",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "704",
  "ini": "め",
  "name": "名探偵コナン 暁のモニュメント",
  "maker": "バンプレスト",
  "releaseDate": "2005/4/21",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "705",
  "ini": "わ",
  "name": "ONE PIECE ドラゴンドリーム！",
  "maker": "バンダイ",
  "releaseDate": "2005/4/28",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "706",
  "ini": "お",
  "name": "お茶犬の夢冒険",
  "maker": "エム・ティー・オー",
  "releaseDate": "2005/4/28",
  "price": "4410",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "707",
  "ini": "り",
  "name": "リラックマなまいにち",
  "maker": "イマジニア",
  "releaseDate": "2005/4/28",
  "price": "5040",
  "genre": "観察・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "708",
  "ini": "さ",
  "name": "ザ・タワーSP",
  "maker": "任天堂",
  "releaseDate": "2005/4/28",
  "price": "4800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "709",
  "ini": "ふ",
  "name": "ファンタジックチルドレン",
  "maker": "バンダイ",
  "releaseDate": "2005/5/19",
  "price": "5040",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "710",
  "ini": "ふ",
  "name": "ぶらぶらドンキー",
  "maker": "任天堂",
  "releaseDate": "2005/5/19",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "711",
  "ini": "ま",
  "name": "魔法先生ネギま！プライベートレッスン だめですぅ 図書館島",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2005/6/9",
  "price": "5040",
  "genre": "アドベンチャー・カードバトル",
  "icNo": "1"
 },
 {
  "id": "712",
  "ini": "の",
  "name": "のののパズル ちゃいリアン",
  "maker": "任天堂",
  "releaseDate": "2005/6/16",
  "price": "3800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "713",
  "ini": "こ",
  "name": "甲虫王者ムシキング グレイテストチャンピオンへの道",
  "maker": "セガ",
  "releaseDate": "2005/6/23",
  "price": "5040",
  "genre": "バトル・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "714",
  "ini": "も",
  "name": "桃太郎電鉄G ゴールド・デッキを作れ！",
  "maker": "ハドソン",
  "releaseDate": "2005/6/30",
  "price": "5040",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "715",
  "ini": "め",
  "name": "メルヘヴン KNOCKIN' ON HEAVEN'S DOOR",
  "maker": "コナミ",
  "releaseDate": "2005/6/30",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "716",
  "ini": "え",
  "name": "エレメンタルジェレイド 封印されし謳",
  "maker": "トミー",
  "releaseDate": "2005/7/7",
  "price": "5040",
  "genre": "アドベンチャー・RPG",
  "icNo": "1"
 },
 {
  "id": "717",
  "ini": "お",
  "name": "おしゃれプリンセス",
  "maker": "カルチャーブレーン",
  "releaseDate": "2005/7/7",
  "price": "5040",
  "genre": "育成・シミュレーション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "718",
  "ini": "れ",
  "name": "レゴ スター・ウォーズ",
  "maker": "アイドス",
  "releaseDate": "2005/7/7",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "719",
  "ini": "き",
  "name": "機動劇団はろ一座 ハロのぷよぷよ",
  "maker": "バンダイ",
  "releaseDate": "2005/7/21",
  "price": "5040",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "720",
  "ini": "り",
  "name": "リロ アンド スティッチ",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2005/7/21",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "721",
  "ini": "き",
  "name": "キム・ポッシブル",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2005/7/21",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "722",
  "ini": "ふ",
  "name": "BLEACHアドバンス 紅に染まる尸魂界",
  "maker": "セガ",
  "releaseDate": "2005/7/21",
  "price": "5040",
  "genre": "コマンドバトル",
  "icNo": "1"
 },
 {
  "id": "723",
  "ini": "ろ",
  "name": "ロボッツ",
  "maker": "ビベンディ ユニバーサル ゲームズ",
  "releaseDate": "2005/7/28",
  "price": "4515",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "724",
  "ini": "こ",
  "name": "金色のガッシュベル!! THE CARD BATTLE for GBA",
  "maker": "バンプレスト",
  "releaseDate": "2005/7/28",
  "price": "5040",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "725",
  "ini": "ふ",
  "name": "ふたりはプリキュア マックスハート マジ!?マジ!? ファイト de INじゃない",
  "maker": "バンダイ",
  "releaseDate": "2005/7/28",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "726",
  "ini": "し",
  "name": "新・ボクらの太陽 逆襲のサバタ",
  "maker": "コナミ",
  "releaseDate": "2005/7/28",
  "price": "5229",
  "genre": "アクション",
  "icNo": "16"
 },
 {
  "id": "727",
  "ini": "ひ",
  "name": "B-伝説！バトルビーダマン 炎魂（ファイヤースピリッツ）",
  "maker": "アトラス",
  "releaseDate": "2005/8/4",
  "price": "5040",
  "genre": "アドベンチャー・3Dバトル",
  "icNo": "1"
 },
 {
  "id": "728",
  "ini": "ま",
  "name": "マダガスカル",
  "maker": "バンダイ",
  "releaseDate": "2005/8/11",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "729",
  "ini": "ふ",
  "name": "プロ麻雀 兵 GBA",
  "maker": "カルチャーブレーン",
  "releaseDate": "2005/8/11",
  "price": "3990",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "730",
  "ini": "こ",
  "name": "昆虫の森の大冒険 ふしぎな世界の住人たち",
  "maker": "カルチャーブレーン",
  "releaseDate": "2005/8/11",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "731",
  "ini": "く",
  "name": "くにおくん熱血コレクション1",
  "maker": "アトラス",
  "releaseDate": "2005/8/25",
  "price": "5040",
  "genre": "スポーツ・アクション",
  "icNo": "1"
 },
 {
  "id": "732",
  "ini": "て",
  "name": "ティム・バートン ナイトメア・ビフォア・クリスマス パンプキン・キング",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2005/9/8",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "733",
  "ini": "わ",
  "name": "わがまま☆フェアリー ミルモでポン！ どきどきメモリアルパニック",
  "maker": "コナミ",
  "releaseDate": "2005/9/8",
  "price": "5040",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "734",
  "ini": "と",
  "name": "ドクターマリオ&パネルでポン",
  "maker": "任天堂",
  "releaseDate": "2005/9/13",
  "price": "2000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "735",
  "ini": "ま",
  "name": "マリオテニス アドバンス",
  "maker": "任天堂",
  "releaseDate": "2005/9/13",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "736",
  "ini": "す",
  "name": "スーパーロボット大戦J",
  "maker": "バンプレスト",
  "releaseDate": "2005/9/15",
  "price": "6090",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "737",
  "ini": "す",
  "name": "スクリューブレイカー 轟振どりるれろ",
  "maker": "任天堂",
  "releaseDate": "2005/9/22",
  "price": "3800",
  "genre": "アクション",
  "icNo": "14"
 },
 {
  "id": "738",
  "ini": "か",
  "name": "ガンスタースーパーヒーローズ",
  "maker": "セガ",
  "releaseDate": "2005/10/6",
  "price": "5040",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "739",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズGX めざせデュエルキング！",
  "maker": "コナミ",
  "releaseDate": "2005/10/13",
  "price": "5229",
  "genre": "カードバトル",
  "icNo": "1"
 },
 {
  "id": "740",
  "ini": "つ",
  "name": "通勤ヒトフデ",
  "maker": "任天堂",
  "releaseDate": "2005/10/13",
  "price": "2800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "741",
  "ini": "ふ",
  "name": "フロンティアストーリーズ",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2005/10/27",
  "price": "5040",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "742",
  "ini": "く",
  "name": "くにおくん熱血コレクション2",
  "maker": "アトラス",
  "releaseDate": "2005/10/27",
  "price": "5040",
  "genre": "スポーツ・アクション",
  "icNo": "1"
 },
 {
  "id": "743",
  "ini": "み",
  "name": "みらくる！ぱんぞう 7つの星の宇宙海賊",
  "maker": "アトラス",
  "releaseDate": "2005/11/3",
  "price": "3990",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "744",
  "ini": "ほ",
  "name": "ポケモン不思議のダンジョン 赤の救助隊",
  "maker": "ポケモン",
  "releaseDate": "2005/11/17",
  "price": "4800",
  "genre": "ダンジョン・RPG",
  "icNo": "1"
 },
 {
  "id": "745",
  "ini": "ろ",
  "name": "ロックマンエグゼ6 電脳獣グレイガ",
  "maker": "カプコン",
  "releaseDate": "2005/11/23",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "746",
  "ini": "ろ",
  "name": "ロックマンエグゼ6 電脳獣ファルザー",
  "maker": "カプコン",
  "releaseDate": "2005/11/23",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "747",
  "ini": "こ",
  "name": "金色のガッシュベル!! うなれ！友情の電撃 ドリームタッグトーナメント",
  "maker": "バンプレスト",
  "releaseDate": "2005/11/24",
  "price": "5040",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "748",
  "ini": "し",
  "name": "シナモン ふわふわ大作戦",
  "maker": "ロケットカンパニー",
  "releaseDate": "2005/12/1",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "749",
  "ini": "す",
  "name": "スーパードンキーコング3",
  "maker": "任天堂",
  "releaseDate": "2005/12/1",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "750",
  "ini": "し",
  "name": "シュガシュガルーン ハートがいっぱい！萌黄学園",
  "maker": "バンダイ",
  "releaseDate": "2005/12/8",
  "price": "5040",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "751",
  "ini": "さ",
  "name": "サモンナイト クラフトソード物語　はじまりの石",
  "maker": "バンプレスト",
  "releaseDate": "2005/12/8",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "752",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅣ アドバンス",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2005/12/15",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "753",
  "ini": "ち",
  "name": "チキン・リトル",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2005/12/15",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "754",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.1 ボンバーマンコレクション",
  "maker": "ハドソン",
  "releaseDate": "2005/12/22",
  "price": "2940",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "755",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.2 ロードランナーコレクション",
  "maker": "ハドソン",
  "releaseDate": "2005/12/22",
  "price": "2940",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "756",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.3 アクションコレクション",
  "maker": "ハドソン",
  "releaseDate": "2005/12/22",
  "price": "2940",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "757",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.4 謎解きコレクション",
  "maker": "ハドソン",
  "releaseDate": "2005/12/22",
  "price": "2940",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "758",
  "ini": "は",
  "name": "ハイ！ハイ！パフィー・アミユミ",
  "maker": "ディースリー・パブリッシャー",
  "releaseDate": "2005/12/22",
  "price": "5040",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "759",
  "ini": "お",
  "name": "お茶犬の冒険島 ほんわか夢のアイランド",
  "maker": "エム・ティー・オー",
  "releaseDate": "2005/12/22",
  "price": "4410",
  "genre": "アクション・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "760",
  "ini": "あ",
  "name": "アニマル横町 どき☆どき 救出大作戦！の巻",
  "maker": "コナミ",
  "releaseDate": "2005/12/22",
  "price": "5229",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "761",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.5 シューティングコレクション",
  "maker": "ハドソン",
  "releaseDate": "2006/1/19",
  "price": "2940",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "762",
  "ini": "は",
  "name": "ハドソンベストコレクションVol.6 冒険島コレクション",
  "maker": "ハドソン",
  "releaseDate": "2006/1/19",
  "price": "2940",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "763",
  "ini": "た",
  "name": "ダブルパック ソニックアドバンス&チューチュー ロケット！",
  "maker": "セガ",
  "releaseDate": "2006/1/26",
  "price": "3990",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "764",
  "ini": "た",
  "name": "ダブルパック ソニックピンボールパーティ&ソニックバトル",
  "maker": "セガ",
  "releaseDate": "2006/1/26",
  "price": "3990",
  "genre": "テーブル・アクション",
  "icNo": "1"
 },
 {
  "id": "765",
  "ini": "た",
  "name": "ダブルパック ソニックバトル&ソニックアドバンス",
  "maker": "セガ",
  "releaseDate": "2006/1/26",
  "price": "3990",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "766",
  "ini": "み",
  "name": "Mr.インクレディブル 強敵アンダーマイナー登場",
  "maker": "セガ",
  "releaseDate": "2006/2/9",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "767",
  "ini": "か",
  "name": "川のぬしづり3&4",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2006/2/9",
  "price": "5040",
  "genre": "RPG・釣り",
  "icNo": "1"
 },
 {
  "id": "768",
  "ini": "く",
  "name": "くにおくん熱血コレクション3",
  "maker": "アトラス",
  "releaseDate": "2006/2/16",
  "price": "5040",
  "genre": "アクション・スポーツ",
  "icNo": "1"
 },
 {
  "id": "769",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズ エキスパート2006",
  "maker": "コナミ",
  "releaseDate": "2006/2/23",
  "price": "5229",
  "genre": "テーブル・カードバトル",
  "icNo": "1"
 },
 {
  "id": "770",
  "ini": "う",
  "name": "うえきの法則 神器炸裂！能力者バトル",
  "maker": "バンプレスト",
  "releaseDate": "2006/3/2",
  "price": "5040",
  "genre": "アクション・格闘",
  "icNo": "1"
 },
 {
  "id": "771",
  "ini": "と",
  "name": "闘牌伝説アカギ 闇に舞い降りた天才",
  "maker": "カルチャーブレーン",
  "releaseDate": "2006/3/3",
  "price": "5040",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "772",
  "ini": "ゆ",
  "name": "ユグドラ・ユニオン",
  "maker": "スティング",
  "releaseDate": "2006/3/23",
  "price": "6090",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "773",
  "ini": "ま",
  "name": "魔法先生ネギま！プライベートレッスン2 お邪魔しますぅ パラサイトでチュ",
  "maker": "マーベラスインタラクティブ",
  "releaseDate": "2006/3/23",
  "price": "5040",
  "genre": "RPG・カードバトル",
  "icNo": "1"
 },
 {
  "id": "774",
  "ini": "は",
  "name": "パワポケダッシュ",
  "maker": "コナミ",
  "releaseDate": "2006/3/23",
  "price": "5229",
  "genre": "アドベンチャー・スポーツ・カード",
  "icNo": "1"
 },
 {
  "id": "775",
  "ini": "く",
  "name": "クレヨンしんちゃん 伝説を呼ぶ オマケの都ショックガーン！",
  "maker": "バンプレスト",
  "releaseDate": "2006/3/23",
  "price": "5040",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "776",
  "ini": "み",
  "name": "みんなのソフトシリーズ ナンプレ アドバンス",
  "maker": "サクセス",
  "releaseDate": "2006/4/6",
  "price": "2940",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "777",
  "ini": "あ",
  "name": "アイシールド21 DEVILBATS DEVILDAYS",
  "maker": "任天堂",
  "releaseDate": "2006/4/6",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "778",
  "ini": "ま",
  "name": "MOTHER3",
  "maker": "任天堂",
  "releaseDate": "2006/4/20",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "779",
  "ini": "あ",
  "name": "アニマル横町 どき☆どき進級試験！の巻",
  "maker": "コナミ",
  "releaseDate": "2006/5/18",
  "price": "4179",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "780",
  "ini": "か",
  "name": "カルチョビット",
  "maker": "任天堂",
  "releaseDate": "2006/5/18",
  "price": "3800",
  "genre": "経営・育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "781",
  "ini": "か",
  "name": "カーズ",
  "maker": "THQジャパン",
  "releaseDate": "2006/7/6",
  "price": "3990",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "782",
  "ini": "ひ",
  "name": "bit Generations DIALHEX",
  "maker": "任天堂",
  "releaseDate": "2006/7/13",
  "price": "2000",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "783",
  "ini": "ひ",
  "name": "bit Generations dotstream",
  "maker": "任天堂",
  "releaseDate": "2006/7/13",
  "price": "2000",
  "genre": "レース",
  "icNo": "10"
 },
 {
  "id": "784",
  "ini": "ひ",
  "name": "bit Generations BOUNDISH",
  "maker": "任天堂",
  "releaseDate": "2006/7/13",
  "price": "2000",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "785",
  "ini": "ひ",
  "name": "bit Generations ORBITAL",
  "maker": "任天堂",
  "releaseDate": "2006/7/27",
  "price": "2000",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "786",
  "ini": "ひ",
  "name": "bit Generations COLORIS",
  "maker": "任天堂",
  "releaseDate": "2006/7/27",
  "price": "2000",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "787",
  "ini": "ひ",
  "name": "bit Generations Soundvoyager",
  "maker": "任天堂",
  "releaseDate": "2006/7/27",
  "price": "2000",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "788",
  "ini": "ひ",
  "name": "bit Generations DIGIDRIVE",
  "maker": "任天堂",
  "releaseDate": "2006/7/27",
  "price": "2000",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "789",
  "ini": "り",
  "name": "リズム天国",
  "maker": "任天堂",
  "releaseDate": "2006/8/3",
  "price": "3800",
  "genre": "リズム・アクション",
  "icNo": "1"
 },
 {
  "id": "790",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅤ アドバンス",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2006/10/12",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "791",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅥ アドバンス",
  "maker": "スクウェア・エニックス",
  "releaseDate": "2006/11/30",
  "price": "5040",
  "genre": "RPG",
  "icNo": "1"
 }
];